.container-fluid {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
}

.container{
	position: relative;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;

	@each $idx, $value in $container-max-widths{
		@if $idx == default{
			width: $value;
		}
		@else{
			@media (max-width: map-get($media, $idx)) {
				width: $value;
			}
		}
	}

	// @media (min-width: 1025px) {
	// 	max-width: 900px;
	// }

	// @media (min-width: 1281px) {
	// 	max-width: 1080px;
	// }

	// // @media (min-width: 1367px) {
	// // 	max-width: 1200px;
	// // }

	// @media (min-width: 1441px) {
	// 	max-width: 1290px;
	// }
}

// .col {
// 	-ms-flex-preferred-size: 0;
// 	flex-basis: 0;
// 	-ms-flex-positive: 1;
// 	flex-grow: 1;
// 	max-width: 100%;
// }

// .col-auto {
// 	-ms-flex: 0 0 auto;
// 	flex: 0 0 auto;
// 	width: auto;
// 	max-width: 100%;
// }


// .col,
// [class*="col-"] {
// 	width: 100%;
// 	padding-right: 12px;
// 	padding-left: 12px;
// }

// .row-evenly {
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin-right: 12px;
// 	margin-left: 12px;
// }

// .row {
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin-right: -12px;
// 	margin-left: -12px;
// 	&.between{
// 		justify-content: space-between;
// 	}
// }

// .no-gutters {
// 	margin-right: 0;
// 	margin-left: 0;
// 	> .col,
// 	> [class*="col-"] {
// 		padding-right: 0;
// 		padding-left: 0;
// 	}
// }


///////////////////////////////////////////////////

$tableColumn: (
	1: 8.33333%, 
	2: 16.66667%, 
	3: 25%, 
	4: 33.33333%,
	5: 41.66667%,
	6: 50%,
	7: 58.33333%,
	8: 66.66667%,
	9: 75%,
	10: 83.33333%,
	11: 91.66667%,
	12: 100%
);

.table-row{
	display: flex;
	flex-wrap: wrap;
	margin-right: 12px;
	margin-left: 12px;

	&.between{
		margin-right: -6px;
		margin-left: -6px;
	}

	@each $m, $size in $media{
		@media (max-width: $size){
			&.no-between-#{$m}{
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}

.index-1--padding{
	padding: 0 24px;
}

@each $idx, $value in $tableColumn{
	.column-#{$idx}{
		flex: 0 0 $value;
		width: $value;
	}
}

@each $m, $size in $media{
	@each $idx, $value in $tableColumn {
		@media (max-width: $size){
			.column-#{$idx}-#{$m}{
				flex: 0 0 $value;
				width: $value;
			}
		}
	}
}

$flex: (
	6: 16.66667,
	5: 20%,
	4: 25%, 
	3: 33.3333%, 
	2: 50%, 
	1: 100%, 
);


@each $idx, $value in $flex {
  .table-column-#{$idx}{
		margin-top: -24px;
		&>*{
			// -ms-flex: 0 0 25%;
			flex: 0 0 $value;
			max-width: $value;
			padding-right: 12px;
			padding-left: 12px;
			padding-top: auto;
	
			// &:nth-of-type(n + #{$idx + 1}){
			padding-top: 24px;
			// }
		}
  }
}

// foreach jsa 
@each $m, $size in $media{
	@each $idx, $value in $flex {
		@media (max-width: $size){
			.table-row.table-column-#{$idx}-#{$m}{
				&>*{
					// -ms-flex: 0 0 25%;
					flex: 0 0 $value;
					max-width: $value;
					padding-right: 12px;
					padding-left: 12px;
					padding-top: auto;

					&:nth-of-type(n + #{$idx + 1}){
						padding-top: 24px;
					}
				}
			}
		}
	}
}

// @each $m, $size in $media{
// 	@each $idx, $value in $flex {
// 		@media (max-width: $size){
// 			.table-row.table-column-#{$idx}-#{$m}{
// 				&>*{
// 					// -ms-flex: 0 0 25%;
// 					flex: 0 0 $value;
// 					max-width: $value;
// 					padding-right: 12px;
// 					padding-left: 12px;
// 					padding-top: auto;
			
// 					&:nth-of-type(n + #{$idx + 1}){
// 						padding-top: 24px;
// 					}
// 				}
// 			}
// 		}
// 	}
// }




// .col-1 {
// 	-ms-flex: 0 0 8.333333%;
// 	flex: 0 0 8.333333%;
// 	max-width: 8.333333%;
// }

// .col-2 {
// 	-ms-flex: 0 0 16.666667%;
// 	flex: 0 0 16.666667%;
// 	max-width: 16.666667%;
// }

// .col-3 {
// 	-ms-flex: 0 0 25%;
// 	flex: 0 0 25%;
// 	max-width: 25%;
// }

// .col-4 {
// 	-ms-flex: 0 0 33.333333%;
// 	flex: 0 0 33.333333%;
// 	max-width: 33.333333%;
// }

// .col-5 {
// 	-ms-flex: 0 0 41.666667%;
// 	flex: 0 0 41.666667%;
// 	max-width: 41.666667%;
// }

// .col-6 {
// 	-ms-flex: 0 0 50%;
// 	flex: 0 0 50%;
// 	max-width: 50%;
// }

// .col-7 {
// 	-ms-flex: 0 0 58.333333%;
// 	flex: 0 0 58.333333%;
// 	max-width: 58.333333%;
// }

// .col-8 {
// 	-ms-flex: 0 0 66.666667%;
// 	flex: 0 0 66.666667%;
// 	max-width: 66.666667%;
// }

// .col-9 {
// 	-ms-flex: 0 0 75%;
// 	flex: 0 0 75%;
// 	max-width: 75%;
// }

// .col-10 {
// 	-ms-flex: 0 0 83.333333%;
// 	flex: 0 0 83.333333%;
// 	max-width: 83.333333%;
// }

// .col-11 {
// 	-ms-flex: 0 0 91.666667%;
// 	flex: 0 0 91.666667%;
// 	max-width: 91.666667%;
// }

// .col-12 {
// 	-ms-flex: 0 0 100%;
// 	flex: 0 0 100%;
// 	max-width: 100%;
// }

// @media (min-width: 576px) {
// 	.col-sm {
// 		-ms-flex-preferred-size: 0;
// 		flex-basis: 0;
// 		-ms-flex-positive: 1;
// 		flex-grow: 1;
// 		max-width: 100%;
// 	}

// 	.col-sm-auto {
// 		-ms-flex: 0 0 auto;
// 		flex: 0 0 auto;
// 		width: auto;
// 		max-width: 100%;
// 	}

// 	.col-sm-1 {
// 		-ms-flex: 0 0 8.333333%;
// 		flex: 0 0 8.333333%;
// 		max-width: 8.333333%;
// 	}

// 	.col-sm-2 {
// 		-ms-flex: 0 0 16.666667%;
// 		flex: 0 0 16.666667%;
// 		max-width: 16.666667%;
// 	}

// 	.col-sm-3 {
// 		-ms-flex: 0 0 25%;
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 	}

// 	.col-sm-4 {
// 		-ms-flex: 0 0 33.333333%;
// 		flex: 0 0 33.333333%;
// 		max-width: 33.333333%;
// 	}

// 	.col-sm-5 {
// 		-ms-flex: 0 0 41.666667%;
// 		flex: 0 0 41.666667%;
// 		max-width: 41.666667%;
// 	}

// 	.col-sm-6 {
// 		-ms-flex: 0 0 50%;
// 		flex: 0 0 50%;
// 		max-width: 50%;
// 	}

// 	.col-sm-7 {
// 		-ms-flex: 0 0 58.333333%;
// 		flex: 0 0 58.333333%;
// 		max-width: 58.333333%;
// 	}

// 	.col-sm-8 {
// 		-ms-flex: 0 0 66.666667%;
// 		flex: 0 0 66.666667%;
// 		max-width: 66.666667%;
// 	}

// 	.col-sm-9 {
// 		-ms-flex: 0 0 75%;
// 		flex: 0 0 75%;
// 		max-width: 75%;
// 	}

// 	.col-sm-10 {
// 		-ms-flex: 0 0 83.333333%;
// 		flex: 0 0 83.333333%;
// 		max-width: 83.333333%;
// 	}

// 	.col-sm-11 {
// 		-ms-flex: 0 0 91.666667%;
// 		flex: 0 0 91.666667%;
// 		max-width: 91.666667%;
// 	}

// 	.col-sm-12 {
// 		-ms-flex: 0 0 100%;
// 		flex: 0 0 100%;
// 		max-width: 100%;
// 	}
// }

// @media (min-width: 768px) {
// 	.col-md {
// 		-ms-flex-preferred-size: 0;
// 		flex-basis: 0;
// 		-ms-flex-positive: 1;
// 		flex-grow: 1;
// 		max-width: 100%;
// 	}

// 	.col-md-auto {
// 		-ms-flex: 0 0 auto;
// 		flex: 0 0 auto;
// 		width: auto;
// 		max-width: 100%;
// 	}

// 	.col-md-1 {
// 		-ms-flex: 0 0 8.333333%;
// 		flex: 0 0 8.333333%;
// 		max-width: 8.333333%;
// 	}

// 	.col-md-2 {
// 		-ms-flex: 0 0 16.666667%;
// 		flex: 0 0 16.666667%;
// 		max-width: 16.666667%;
// 	}

// 	.col-md-3 {
// 		-ms-flex: 0 0 25%;
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 	}

// 	.col-md-4 {
// 		-ms-flex: 0 0 33.333333%;
// 		flex: 0 0 33.333333%;
// 		max-width: 33.333333%;
// 	}

// 	.col-md-5 {
// 		-ms-flex: 0 0 41.666667%;
// 		flex: 0 0 41.666667%;
// 		max-width: 41.666667%;
// 	}

// 	.col-md-6 {
// 		-ms-flex: 0 0 50%;
// 		flex: 0 0 50%;
// 		max-width: 50%;
// 	}

// 	.col-md-7 {
// 		-ms-flex: 0 0 58.333333%;
// 		flex: 0 0 58.333333%;
// 		max-width: 58.333333%;
// 	}

// 	.col-md-8 {
// 		-ms-flex: 0 0 66.666667%;
// 		flex: 0 0 66.666667%;
// 		max-width: 66.666667%;
// 	}

// 	.col-md-9 {
// 		-ms-flex: 0 0 75%;
// 		flex: 0 0 75%;
// 		max-width: 75%;
// 	}

// 	.col-md-10 {
// 		-ms-flex: 0 0 83.333333%;
// 		flex: 0 0 83.333333%;
// 		max-width: 83.333333%;
// 	}

// 	.col-md-11 {
// 		-ms-flex: 0 0 91.666667%;
// 		flex: 0 0 91.666667%;
// 		max-width: 91.666667%;
// 	}

// 	.col-md-12 {
// 		-ms-flex: 0 0 100%;
// 		flex: 0 0 100%;
// 		max-width: 100%;
// 	}
// }

// @media (min-width: 1025px) {
// 	.col-lg {
// 		-ms-flex-preferred-size: 0;
// 		flex-basis: 0;
// 		-ms-flex-positive: 1;
// 		flex-grow: 1;
// 		max-width: 100%;
// 	}

// 	.col-lg-auto {
// 		-ms-flex: 0 0 auto;
// 		flex: 0 0 auto;
// 		width: auto;
// 		max-width: 100%;
// 	}

// 	.col-lg-1 {
// 		-ms-flex: 0 0 8.333333%;
// 		flex: 0 0 8.333333%;
// 		max-width: 8.333333%;
// 	}

// 	.col-lg-2 {
// 		-ms-flex: 0 0 16.666667%;
// 		flex: 0 0 16.666667%;
// 		max-width: 16.666667%;
// 	}

// 	.col-lg-3 {
// 		-ms-flex: 0 0 25%;
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 	}

// 	.col-lg-4 {
// 		-ms-flex: 0 0 33.333333%;
// 		flex: 0 0 33.333333%;
// 		max-width: 33.333333%;
// 	}

// 	.col-lg-5 {
// 		-ms-flex: 0 0 41.666667%;
// 		flex: 0 0 41.666667%;
// 		max-width: 41.666667%;
// 	}

// 	.col-lg-6 {
// 		-ms-flex: 0 0 50%;
// 		flex: 0 0 50%;
// 		max-width: 50%;
// 	}

// 	.col-lg-7 {
// 		-ms-flex: 0 0 58.333333%;
// 		flex: 0 0 58.333333%;
// 		max-width: 58.333333%;
// 	}

// 	.col-lg-8 {
// 		-ms-flex: 0 0 66.666667%;
// 		flex: 0 0 66.666667%;
// 		max-width: 66.666667%;
// 	}

// 	.col-lg-9 {
// 		-ms-flex: 0 0 75%;
// 		flex: 0 0 75%;
// 		max-width: 75%;
// 	}

// 	.col-lg-10 {
// 		-ms-flex: 0 0 83.333333%;
// 		flex: 0 0 83.333333%;
// 		max-width: 83.333333%;
// 	}

// 	.col-lg-11 {
// 		-ms-flex: 0 0 91.666667%;
// 		flex: 0 0 91.666667%;
// 		max-width: 91.666667%;
// 	}

// 	.col-lg-12 {
// 		-ms-flex: 0 0 100%;
// 		flex: 0 0 100%;
// 		max-width: 100%;
// 	}
// }

// @media (min-width: 1200px) {
// 	.col-xl {
// 		-ms-flex-preferred-size: 0;
// 		flex-basis: 0;
// 		-ms-flex-positive: 1;
// 		flex-grow: 1;
// 		max-width: 100%;
// 	}

// 	.col-xl-auto {
// 		-ms-flex: 0 0 auto;
// 		flex: 0 0 auto;
// 		width: auto;
// 		max-width: 100%;
// 	}

// 	.col-xl-1 {
// 		-ms-flex: 0 0 8.333333%;
// 		flex: 0 0 8.333333%;
// 		max-width: 8.333333%;
// 	}

// 	.col-xl-2 {
// 		-ms-flex: 0 0 16.666667%;
// 		flex: 0 0 16.666667%;
// 		max-width: 16.666667%;
// 	}

// 	.col-xl-3 {
// 		-ms-flex: 0 0 25%;
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 	}

// 	.col-xl-4 {
// 		-ms-flex: 0 0 33.333333%;
// 		flex: 0 0 33.333333%;
// 		max-width: 33.333333%;
// 	}

// 	.col-xl-5 {
// 		-ms-flex: 0 0 41.666667%;
// 		flex: 0 0 41.666667%;
// 		max-width: 41.666667%;
// 	}

// 	.col-xl-6 {
// 		-ms-flex: 0 0 50%;
// 		flex: 0 0 50%;
// 		max-width: 50%;
// 	}

// 	.col-xl-7 {
// 		-ms-flex: 0 0 58.333333%;
// 		flex: 0 0 58.333333%;
// 		max-width: 58.333333%;
// 	}

// 	.col-xl-8 {
// 		-ms-flex: 0 0 66.666667%;
// 		flex: 0 0 66.666667%;
// 		max-width: 66.666667%;
// 	}

// 	.col-xl-9 {
// 		-ms-flex: 0 0 75%;
// 		flex: 0 0 75%;
// 		max-width: 75%;
// 	}

// 	.col-xl-10 {
// 		-ms-flex: 0 0 83.333333%;
// 		flex: 0 0 83.333333%;
// 		max-width: 83.333333%;
// 	}

// 	.col-xl-11 {
// 		-ms-flex: 0 0 91.666667%;
// 		flex: 0 0 91.666667%;
// 		max-width: 91.666667%;
// 	}

// 	.col-xl-12 {
// 		-ms-flex: 0 0 100%;
// 		flex: 0 0 100%;
// 		max-width: 100%;
// 	}
// }

// @media (min-width: 1360px) {
// 	.col-2xl {
// 		-ms-flex-preferred-size: 0;
// 		flex-basis: 0;
// 		-ms-flex-positive: 1;
// 		flex-grow: 1;
// 		max-width: 100%;
// 	}

// 	.col-2xl-auto {
// 		-ms-flex: 0 0 auto;
// 		flex: 0 0 auto;
// 		width: auto;
// 		max-width: 100%;
// 	}

// 	.col-2xl-1 {
// 		-ms-flex: 0 0 8.333333%;
// 		flex: 0 0 8.333333%;
// 		max-width: 8.333333%;
// 	}

// 	.col-2xl-2 {
// 		-ms-flex: 0 0 16.666667%;
// 		flex: 0 0 16.666667%;
// 		max-width: 16.666667%;
// 	}

// 	.col-2xl-3 {
// 		-ms-flex: 0 0 25%;
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 	}

// 	.col-2xl-4 {
// 		-ms-flex: 0 0 33.333333%;
// 		flex: 0 0 33.333333%;
// 		max-width: 33.333333%;
// 	}

// 	.col-2xl-5 {
// 		-ms-flex: 0 0 41.666667%;
// 		flex: 0 0 41.666667%;
// 		max-width: 41.666667%;
// 	}

// 	.col-2xl-6 {
// 		-ms-flex: 0 0 50%;
// 		flex: 0 0 50%;
// 		max-width: 50%;
// 	}

// 	.col-2xl-7 {
// 		-ms-flex: 0 0 58.333333%;
// 		flex: 0 0 58.333333%;
// 		max-width: 58.333333%;
// 	}

// 	.col-2xl-8 {
// 		-ms-flex: 0 0 66.666667%;
// 		flex: 0 0 66.666667%;
// 		max-width: 66.666667%;
// 	}

// 	.col-2xl-9 {
// 		-ms-flex: 0 0 75%;
// 		flex: 0 0 75%;
// 		max-width: 75%;
// 	}

// 	.col-2xl-10 {
// 		-ms-flex: 0 0 83.333333%;
// 		flex: 0 0 83.333333%;
// 		max-width: 83.333333%;
// 	}

// 	.col-2xl-11 {
// 		-ms-flex: 0 0 91.666667%;
// 		flex: 0 0 91.666667%;
// 		max-width: 91.666667%;
// 	}

// 	.col-2xl-12 {
// 		-ms-flex: 0 0 100%;
// 		flex: 0 0 100%;
// 		max-width: 100%;
// 	}
// }

// .col,
// [class*="col-"] {
// 	width: 100%;
// 	padding-right: 10px;
// 	padding-left: 10px;
// }



// @each $idx, $value in $flex {
//   .table-column-#{$idx}{
// 		&>*{
// 			// -ms-flex: 0 0 25%;
// 			flex: 0 0 $value;
// 			max-width: $value;
// 			padding-right: 12px;
// 			padding-left: 12px;
	
// 			&:nth-of-type(n + #{$idx + 1}){
// 				padding-top: 24px;
// 			}
// 		}
//   }
// }

// @for $i from 1 through 12{
// 	.table-col-#{$i}{

// 	}
// }

// .row-col3{

// 	&>*{
// 		// -ms-flex: 0 0 25%;
// 		flex: 0 0 33.333333%;
// 		max-width: 33.333333%;
// 		padding-right: 12px;
// 		padding-left: 12px;

// 		&:nth-of-type(n + 5){
// 			padding-top: 24px;
// 		}
// 	}
// }
// .row-col4{
// 	&>*{
// 		// -ms-flex: 0 0 25%;
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 		padding-right: 12px;
// 		padding-left: 12px;

// 		&:nth-of-type(n + 5){
// 			padding-top: 24px;
// 		}
// 	}
// }

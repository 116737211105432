$spaceHeader: (
	s42: (
    default: 42px,
    pl: 28px,
    pm: 14px,
  ),
);

$blockHeader: (
	top: (
    default: 60px,
    ps: 52px,
    tl: 48px,
    ts: 42px,
  ),
  main: (
    default: 123px,
    ps: 110px,
    tl: 90px,
    ts: 80px,
    ml: 72px,
  ),
  logoWidth: (
    defalut: 186px,
    pl: 172px,
    pm: 150px,
  ),
);

@mixin blockHeader($key, $name, $side: '-1'){
  @each $idx, $val in map-get($blockHeader, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}

@mixin spaceHeader($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spaceHeader, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
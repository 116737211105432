.service-4{
  .service-spa{
    @include space(s90, margin-top);

    &__title{
      width: 100%;
      text-align: center;

      @include spaceService4(bottomTitle, margin-bottom);
    }
    &__desc{
      @include spaceService4(bottomDesc, margin-bottom);
      text-align: center;
    }
    &__img{
      @include spaceService4(bottomImg, padding-bottom);
      position: relative;
      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 75%;
        background-color: #EEEEEE;
      }
      @include rp(tl){
        padding-left: 56px;
        padding-right: 56px;
      }
      @include rp(mm){
        padding-left: 48px;
        padding-right: 48px;
      }
      .imgs{
        &-item{
          @include blockService(imgSpaService, height);
        }
      }


      .swiper-button-next::after,
      .swiper-button-prev::after{
        content: "";
      }
      $wh: 43px;
      .swiper-button-next,
      .swiper-button-prev{
        width: $wh;
        height: $wh;
        background-color: white;
        border-radius: 300px;

        @media (max-width: map-get($media, tl)){
          width: 36px;
          height: 36px;
        }
  
        @media (max-width: map-get($media, mx)){
          width: 30px;
          height: 30px;
        }
      }
      .swiper-button-next{
        right: calc(7% - #{$wh});
        @include rp(tl){
          right: calc(-2% - #{$wh});
        }
      }
      .swiper-button-prev{
        left: calc(7% - #{$wh});
        @include rp(tl){
          left: calc(-2% - #{$wh});
        }
      }
    }
  }
}
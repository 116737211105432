
$spacewedding2:(
  s48:(
    default: 48px,
    pl: 40px,
    pm: 32px,
    tx: 20px,
    ts: 16px,
    mx: 16px,
    
  ),
  s70:(
    default: 70px,
    pl: 56px,
    pm: 40px,
    tx: 32px,
    ts: 32px,
    mx: 28px,
  ),
  s80:(
    default:80px,
    pm: 60px,
    ps: 50px,
    tx: 40px,
  ),
  s140:(
    default:140px,
    pl: 100px,
    pm: 80px,
    tx: 60px,
    tm: 0,
  ),
);
$spacewedding3:(
    s32: (
      default: 32px,
      pm: 28px, 
      tx: 20px,
      tl: 16px,
      ts: 12px,
      mx: 10px,
    ),
    s72:(
      default: 72px,
       pl: 64px,
       pm: 48px,
       ps: 40px,
       tx: 32px,
       tl: 28px,
       tm: 24px,
       ts: 18px,
       mx: 14px,
       mm: 10px,
      // ms: 32px,
    ),
    s80:(
      default:80px,
      pm: 60px,
      ps: 50px,
      tx: 40px,
    ),
    s104:(
      default: 104px,
      pl: 96px,
      pm: 72px,
      px: 64px,
      tx: 56px,
      tl: 44px,
      tm: 30px,
      ts: 22px,
      mx: 18px,
      ml: 14px,
      // mm: 8px,
      // ms: 4px,
      
    ),
    
);
$spacewedding4:(
  s16:(
    default: 16px,
    pm: 12px,
    tx: 8px,
  ),
  s20:(
    default: 20px,
  ),
  s32:(
    default: 32px,
    pm: 28px,
  ),
  s40:(
    default: 40px,
  ),
  s80:(
    default: 80px,
    tx: 72px,
    ts: 64px,
    mx: 56px,
    mm: 48px,
  ),
  leftList:(
    default: 20px,
  ),
  s108:(
    default: 108px,
    pl: 88px,
    ps: 68px,
    tx: 48px,
  ),
  
);
$spacewedding5:(
  s64:(
    default: 64px,
    pl: 60px,
    pm: 50px,
    ps: 48px,
    tx: 32px,
  ),
  s80:(
    default: 80px,
    pl: 60px,
    pm: 50px,
    ps: 40px,
    tx: 32px,
  ),
);
$blockwedding2:(
  s784:(
    default:748px,
  ),
  hBoxWedding4:(
    default: 520px,
    tx: 450px,
    ts: 420px,
    mx: 380px,
    ml: 350px,
    
  ), 
  wImgWedding2:(
    default: 820px,
    pl: 700px,
    pm: 600px,
    tx: 480px,
    tm: 100%,
  ),
  hImgWedding2:(
    default: 750px,
    pl: 680px,
    pm: 630px,
    tx: 540px,
    tl: 500px,
    ts: 480px,
    mx: 450px,
    ml: 400px,
    mm: 350px,
  ),
  widthImgWedding5:(
    default: 630px,
   
  ),
  heightImgWedding5:(
    default: 430px,
    pl: 360px,
    pm: 320px,
    ps: 295px,
    tx: 220px,
    tl: 180px,
    tm: 160px,
    ts: 120px,
    mx: 100px,
  ),
  s543:(
    default:543px,
  ),
  s50:(
    default:50px,
    ps:15px,
  ),
  s24:(
    default:24px 0,
    ps:18px 0,
  ),
);
@mixin spacewedding2($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spacewedding2, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}

@mixin sizewedding2($key, $name, $side: '-1'){
  @each $idx, $val in map-get($sizewedding2, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
@mixin spacewedding3($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spacewedding3, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
@mixin spacewedding4($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spacewedding4, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
@mixin spacewedding5($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spacewedding5, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
@mixin blockwedding2($key, $name, $side: '-1'){
  @each $idx, $val in map-get($blockwedding2, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////
//////////////// BANNER ////////////////////////////////////

.banner {
  max-width: 1920px;
  @include blockBanner(heightBanner, height);
  // background: url("../assets/images/layout/banner/HBPQ 1.png");
  position: relative;
  &-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .overlay{
    @include rp(tx){
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(216,215,215,.1);
        z-index: 1;
    }
  }
  .container{
    height: 100%;
  }

  &-content {
    width: 50%;
    @include rp(mx){
      width: 80%;
    }
    @include rp(mm){
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 25%;
    left: 50%;
    @include rp(tx){
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    z-index: 1;
    &__titleSub {
      padding-bottom: 12px;
      margin-bottom: 12px;
      @include rp(tx){
        padding-bottom: 8px;
        margin-bottom: 8px;
      }
      position: relative;
      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        height: 1px;
        width: 100%;
        background: map-get($color, main);
      }
      & > * {
        @include uppercase;
        @include size(s41);
        @include rp(pm){
          font-size: 3.5rem;
        }
        @include rp(tx){
          font-size: 3rem;
          text-shadow: 0 3px 6px rgba(0,0,0,.16);
        }
        @include rp(tm){
          font-size: 2.8rem;
        }
        @include rp(ts){
          font-size: 2.6rem;
        }
        @include rp(ml){
          font-size: 2rem;
        }
        @include light;
        color: map-get($color, main);
      }
    }
    &__title {
      padding-bottom: 12px;
      margin-bottom: 12px;
      @include rp(tx){
        padding-bottom: 8px;
        margin-bottom: 8px;
      }
      position: relative;
      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 60%;
        background: map-get($color, main);
      }
      & > * {
        @include uppercase;
        @include family--primary;
        @include size(s73);
        @include rp(pm){
          font-size: 6.5rem;
        }
        @include rp(tx){
          font-size: 5.2rem;
          text-shadow: 0 3px 6px rgba(0,0,0,.16);
        }
        @include rp(tm){
          font-size: 4.4rem;
        }
        @include rp(ts){
          font-size: 4rem;
        }
        @include rp(ml){
          font-size: 3.6rem;
        }
        @include bold;
        color: map-get($color, main);
      }
    }
    &__desc {
      & > * {
        @include uppercase;
        @include size(s41);
        @include rp(pm){
          font-size: 3.5rem;
        }
        @include rp(tx){
          font-size: 3rem;
          text-shadow: 0 3px 6px rgba(0,0,0,.16);
          letter-spacing: 20px;
        }
        @include rp(tm){
          font-size: 2.8rem;
        }
        @include rp(ts){
          font-size: 2.6rem;
        }
        @include rp(mx){
          letter-spacing: 10px;
        }
        @include rp(ml){
          font-size: 2rem;
        }
        @include light;
        letter-spacing: 30px;
        color: map-get($color, main);
      }
    }
  }

}
//////////////// BANNER ///////////////////////////////
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
//////////////// SUB BANNER /////////////////////////////

.banner-sub{
  position: relative;
  @include block(banner, height);

  &__img{
    width: 100%;
    height: 100%;

    img{
      object-position: bottom;
    }
  }

  &-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    &__title{
      &>*{
        @include family--primary;
        @include bold;
        color: map-get($color, mainBg);
        @include size(s73);
      }
    }

    &__bb{
      @include space(s48, margin-top);

      .bb{
        list-style-type: none;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        &>*{
          &:not(:first-child){
            // margin-left: 36px;
            position: relative;
            // padding-left: 24px;
            @include space(s34, padding-left);

            svg{
              width: map-get($sizes, s8);
              position: absolute;
              top: 50%;
              @include space(s17-breadcrumb, left);
              transform: translate(-50%,-50%);

              path{
                stroke: map-get($color, mainBg);
              }
            }
          }
        }

        &-item{
          &>*{
            color: map-get($color, mainBg);
            @include size(s18);
          }

          &__link{
            display: block;
            padding-top: .6rem;
            padding-bottom: .6rem;
          }

          &.active{
            .bb-item__link{
              position: relative;

              &::after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: map-get($color, breadcrumbActive);
              }
            }
          }
        }
      }
    }
  }
}

//////////////// SUB BANNER /////////////////////////////
////////////////////////////////////////////////////////////

.box-pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;

  li{
    color: map-get($color, bulletText);
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 4.3rem;
    @include rp(pm){
      height: 4rem;
    }
    @include rp(tl){
      height: 3.8rem;
    }
    @include rp(mx){
      height: 3.2rem;
    }

    font-size: 2.6rem;
    @include rp(pm){
      font-size: 2.4rem;
    }
    @include rp(tl){
      font-size: 2.2rem;
    }
    @include rp(mx){
      font-size: 2rem;
    }

    a{
      color: map-get($color, bulletText);
      font-weight: bold;

      display: flex;
      justify-content: center;
      align-items: center;

      height: 4.3rem;
      @include rp(pm){
        height: 4rem;
      }
      @include rp(tl){
        height: 3.8rem;
      }
      @include rp(mx){
        height: 3.2rem;
      }

      font-size: 2.6rem;
      @include rp(pm){
        font-size: 2.4rem;
      }
      @include rp(tl){
        font-size: 2.2rem;
      }
      @include rp(mx){
        font-size: 2rem;
      }
    }
    svg{
      width: 2rem;
      @include rp(pm){
        width: 1.8rem;
      }
      @include rp(tl){
        width: 1.6rem;
      }
      @include rp(mx){
        width: 1.5rem;
      }
    }

    cursor: pointer;
    user-select: none;

    &.active{
      color: map-get($color, bulletTextActive);
      text-decoration: underline;

      cursor: initial;

      a{
        color: map-get($color, bulletTextActive);
        text-decoration: underline;

        cursor: initial;
      }
    }
  }
  li.next,
  li.prev{
    a{
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    background-color: map-get($color, bulletBg);

    width: 4.3rem;
    height: 4.3rem;
    
    @include rp(pm){
      width: 4rem;
      height: 4rem;
    }
    @include rp(tl){
      width: 3.8rem;
      height: 3.8rem;
    }
    @include rp(mx){
      width: 3.2rem;
      height: 3.2rem;
    }

    border-radius: 200px;

    &.btn-hidden{
      background-color: map-get($color, bulletBgHidden);
      cursor: initial;

      a{
        cursor: initial;
      }
    }
  }

  li.prev{
    svg{
      transform: rotate(180deg);
    }
  }
  
  li + li{
    margin-left: 36px;

    @include rp(pm){
      margin-left: 28px;
    }
    @include rp(tl){
      margin-left: 24px;
    }
    @include rp(mx){
      margin-left: 18px;
    }
  }
}
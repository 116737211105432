.margin-left-auto{
  margin-left: auto;
}
.margin-right-auto{
  margin-right: auto;
}
.margin-auto{
  margin: auto;
}
.img-auto{
  width: auto;
  height: auto;
  object-fit: contain;
}

.btn-link--booking{
  display: inline-block;
  background: map-get($color, main );
  padding: 8px 16px;
  color: white;
  font-weight: bold;
  @include size(s17);

}
.btn-link--more{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: map-get($color, main );
  padding: 8px 0px;
  
  @include size(s15);
  
  svg{
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: map-get($media, mx)){
      margin-left: 4px;
    }
  }
}

.title-primary{
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  position: relative;

  @include rp(mx){
    width: 100%;
  }

 .title-shadow{
    line-height: 1.05;

    // @include size(s215);
    // @include rp(ps){
    //   font-size: 18.5rem;
    // }
    // @include rp(tl){
    //   font-size: 13.5rem;
    // }

    font-size: 16rem;
    @include rp(ps){
      font-size: 12rem;
    }
    @include rp(tl){
      font-size: 10rem;
    }


    @include family--primary;
    color: map-get($color, textShadow );
    font-weight: bold;

    @include rp(mx){
      display: none;
    }
  }

  .title-content{
    // @include size(s51);
    @include title(s51);
    @include family--primary;
    font-weight: bold;
    color: map-get($color, main );
    
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    @include rp(mx){
      position: initial;
      top: initial;
      left: initial;
      transform: none;
    }

    // min-width: 144px;
    width: 100%;
    text-align: center;
  }
}

.title-second{
  &>*{
    color: map-get($color, main);
    // @include size(s51);
    @include title(s51);

    @include bold;
    @include family--primary;
  }
  position: relative;
  &::after{
    position: absolute;
    content: "";
    bottom: -9px;
    left: 0;
    height: 6px;
    min-width: 122px;
    background-color: map-get($color, underline);
  }
}

.service-wrapper{
  &-container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    @include rp(ts) {
      flex-direction: column;
    }
  }
  &__img {
    @include rp(ts) {
      @include blockService(imgShopService, max-height);
    }
  }

  &__content{

    margin-left: auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .box{
      &__title{
        @include spaceService3(bottomShopTitle, margin-bottom);
      }

      &__desc{
        line-height: 1.4;
      }
    }
  }
}

.endow {
  &__content {
    display: flex;
  }

  &__left{
    @include spaceIndex3(s48, padding-right);

    @include rp(tm) {
      padding: 0 0 24px 0;
    }

    &--list {
      list-style-type: none;

      max-width: 276px;
      @include rp(pm) {
        max-width: 95%;
      }
      @include rp(tl) {
        max-width: 90%;
      }
      @include rp(tm) {
        max-width: 100%;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
        
        display: flex;
        // justify-content: space-between;
      }
    }
    &--items {
      // display: inline-block;

      @include spaceIndex(s32, padding, 0);
      cursor: pointer;
      position: relative;

      &:not(:last-child)::after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 95%;
        height: 1px;
        background: map-get($color, base);
        content: "";
      }

      @include rp(tm) {
        white-space: nowrap;
        &:not(:last-child){
          margin-right: 36px;
        }
        &:not(:last-child)::after {
          width: 0%;
        }
        margin: 0;
        
      }

      @include rp(ts) {
        &:not(:last-child){
          margin-right: 24px;
        }
      }
    }
    &--desc {
      @include title(s45);

      @include rp(tm) {
        font-size: 4rem;
      }
      @include rp(tl) {
        font-size: 3.8rem;
      }
      @include rp(ts) {
        font-size: 3.4rem;
      }
      @include rp(mx) {
        font-size: 3rem;
      }
      @include rp(ml) {
        font-size: 2.6rem;
      }
      @include rp(mm) {
        font-size: 2.2rem;
      }
      @include family--primary;
      font-weight: bold;
      color: map-get($color, base);
      &.active {
        color: map-get($color, main);
      }
    }
  }
  &__slide{
    display: none !important;
    &.active{
      display: block !important;
    }
  }
  &__right {
    @include rp(tm) {
      padding: 0 !important;
    }
    position: relative;
    
    .endow-box {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 8px;
        background: map-get($color, main);
        top: 99%;
        left: 0;
      }
      &__img {
        @include blockIndex(imgIndex3, height);
      }
      &__content {
        @include spaceIndex(s34-2, padding);
        background: map-get($color, navigation);
      }
      &__title {
        &--size {
          @include size(s26);
          @include rp(ts) {
            font-size: 2.4rem;
          }
          font-weight: bold;
          color: map-get($color, main);
          
        }
      }
      &__desc {
        & > * {
          @include spaceIndex(s24, margin, 0);
          @include size(s18);
          @include rp(ts) {
            font-size: 1.6rem;
          }
          @include p-line-clamp(2);
          line-height: 1.4;
        }
      }
    }

    .endow-box__more {
      svg {
        margin-left: 8px;
      }
    }
  }
}

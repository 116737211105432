#utilities-2__wrapper{
    
    .tour-title{
        width: 100%;
        text-align: center;
        // @include utilitiesBox(s90, margin-top);
        @include space(s90, margin-top);
        @include space(s48, margin-bottom);
    }
    .custom-bg{
        position: relative;
        z-index: 1;
        &::after{
            position: absolute;
            @include utilitiesBox(m-t-90, top);
            width: 100%;
            height: 100%;
            left: 0;
            content: "";
            background: map-get($color, buttonSwiper );
            z-index: -1;
        }
    }
    .section-box{
        .section-1{
            &__image{
                @include blockUtilities(utilities-2, height);
                margin: auto;
            }
            &__text{
                color: map-get($color, base);
                margin: auto;
                p{
                    line-height: 1.6;
                    text-align: justify;
                  }
                @include utilitiesBox(p35, padding);
                @include rp(ps){
                    font-size: 1.6rem;
                }
                @include rp(tl){
                    font-size: 1.4rem;
                }
                @include rp(mm){
                    width: 80%;
                    flex:  0 0 80%;
                }
            }
        }   
        .section-2{
            @include utilitiesBox(m70, margin);
            position: relative;
            display: flex;
            align-items: center;
            &__image{
                @include rp(mx){
                    position: relative;
                    &::after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: map-get($color, bg-utilities-2 );
                    }
                }
                @include rp(mx){
                    width: 100%;
                    flex: unset;
                }
                @include blockUtilities(utilities-3, height);
            }
            &__text{
                @include rp(mx){
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0;
                    width: 80%;
                    height: 100%;
                    .section-2__text--decs{
                        .decs,
                        .time{
                            color: map-get($color, textInput );
                        }
                    }
                }
                
                margin-left: auto;
                &--decs{
                    p{
                        text-align: justify;
                        line-height: 1.4;
                    }
                }
                &--title{
                    @include utilitiesBox(m-b-70, margin-bottom);
                    position: relative;
                    // @include size(s51);
                    &>*{
                        @include size(s51);
                        color: map-get($color, main );
                        @include family--primary;
                        font-weight: bold;

                        @include rp(pm){
                            font-size: 4.0rem;
                        }
                        @include rp(ps){
                            font-size: 3.6rem;
                        }
                        @include rp(tx){
                            font-size: 3.2rem;
                        }
                        @include rp(tl){
                            font-size: 3rem;
                        }
                        @include rp(ts){
                            font-size: 2.8rem;
                        }
                        @include rp(mm){
                            font-size: 3rem;
                        }
                        @include rp(sl){
                            font-size: 2.4rem;
                        }
                    }
                    
                    &::after{
                        position: absolute;
                        bottom: -15px;
                        left: 0;
                        content: "";
                        background: map-get($color, underline);
                        height: 6px;
                        width: 20%;
                        @include rp(pl){
                            bottom: -10px;
                        }
                        @include rp(pm){
                            height: 5px;
                        }
                        @include rp(ml){
                            height: 4px;
                            bottom: -5%;
                        }
                    }
                }
            }
        }   
    }
}
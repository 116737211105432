.index-endow {
    .title-primary {
        width: 100%;
        display: flex;
        justify-content: center;

        @include space(s48, margin-top);
        @include space(s48, margin-bottom);
    }
    .endow__right {
        position: relative;
        @include rp(tx) {
            padding: 0 50px;
        }
        $wh: 43px;
        .swiper-button-next,
        .swiper-button-prev {
            &::after {
                content: "";
            }
            background-color: map-get($color, buttonSwiper-2);

            width: $wh;
            height: $wh;

            @media (max-width: map-get($media, tl)) {
                $wh: 36px;

                width: $wh;
                height: $wh;
            }

            @media (max-width: map-get($media, mx)) {
                $wh: 30px;

                width: $wh;
                height: $wh;
            }

            border-radius: 1000px;
        }

        $spaceButtonSwiper: -35px;
        .swiper-button-next {
            right: calc(#{$spaceButtonSwiper} - #{$wh});
            @include rp(pm) {
                right: calc(-15px - #{$wh});
            }
            @include rp(tx) {
                right: 0;
            }
            @include rp(tm) {
                right: 18px;
            }
        }
        .swiper-button-prev {
            left: calc(#{$spaceButtonSwiper} - #{$wh});
            @include rp(pm) {
                left: calc(-15px - #{$wh});
            }
            @include rp(tx) {
                left: 0;
            }
            @include rp(tm) {
                left: 18px;
            }
        }

        & .endow__slide {
            display: none;
        }
    }
}

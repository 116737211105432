// $default: calc(14px + (16 - 14) * ((100vw - 400px) / (1600 - 400)));
$family: (
	primary: "Judson",
	second: "Open Sans",
);

$color: (
	main: #004a8f,
	// title, màu nền chính
	mainOverlay: #0055a5,
	mainBg: #fff,
	mainBgOverlay: rgba(#fff, 0.5),
	// bgDark: rgba(#000,.15),
	base: #707070,
	// text
	base-2: #bcbcbc,
	// text
	baseBold: #848484,
	baseBlur: #c4c4c4,
	breadcrumb: #848484,
	breadcrumbActive: #ed1c24,
	dateTime: #c4c4c4,
	navigation: #f8f8f8,
	textInput: #000,
	subInput: #bcbcbc,
	underline: #ed1c24,
	// gạch chân
	textShadow: rgba(#bcbcbc, 0.3),
	// Đoạn text lớn đằng sau title
	boxOverlayActive: rgba(#000, 0.7),
	boxOverlay: rgba(#000, 0.16),
	boxLightOverlay: rgba(#ffffff, 0.95),
	boxVideoOverlay: rgba(#707070, 0.7),
	iconMain: #004a8f,
	iconMainBgActive: #eeeeee,

	buttonSwiper: #eeeeee,
	buttonSwiper-2: #F8F8F8,

	bulletBgHidden: #F8F8F8,
	bulletBg: #c4c4c4,
	bulletText: #c4c4c4,
	bulletTextActive: #004a8f,
	bulletBtn: #707070,
	//cuisine bg overlay
	bg-cuisineOverlay: rgba(#ECECEC,0.4),
	// utilities bg
	bg-utilities-2: rgba(#EEEEEE, 0.75),

	// footer
	footer-bg: #F8F8F8,

	// intro
	intro-overlay: rgba(#004A8F, 0.65),
	intro-bg-2: #eeeeee,

	// popup
	close-popup: #000,
	close-text-popup: rgb(200, 200, 200),

	// scroll
	track: #F1F1F1,
	thumb: #70707070,
	thumbHover: #707070,

	// infor
	error: #ED4337,
	success: #28a745,
	
	// intro-infor
	intro-bg-5: #FBFBFB,
	infor-text: #848484,

	// background popup
	bgPopup: #003F7A,

	percent: #ED1C24,
);

$letterSpacing: (
	ls20: (
		default: 20px,
		// dùng các key của $media
		ps: 10px,
	),
	ls18: (
		default: 18px,
		// dùng các key của $media
		ps: 10px,
	),
);

// line height dùng số không dùng pixel nha
$lineHeight: (
	lh30: (
		default: 1.4,
		// dùng các key của $media
		ps: 1.2,
	),
	
);

// dùng rem (1 rem = 10px)
// Cách sử dụng: @include size(s12); --> sử dụng biến s12
// ----> tương đương với font-size: 1.2rem;
// 1920 -> 10px -> 1 rem = 10px
$sizes: (
	s4: 0.4rem,
	s8: 0.8rem,
	s11: 1.1rem,
	s12: 1.2rem,
	// header top, LINK MORE
	s15: 1.5rem,
	s16: 1.6rem,
	// BUTTON BOOK
	s17: 1.7rem,
	// header navigation, INHERIT, TEXT, READMORE
	s18: 1.8rem,
	// header top Đặt phòng
	s21: 2.1rem,
	s24: 2.4rem,
	// SUB TITLE
	s26: 2.6rem,
	s31: 3.1rem,
	s36: 3.6rem,
	// banner Resort
	s40: 4rem,
	// banner WelcomeTo, TITLE 2
	s41: 4.1rem,
	s42: 4.2rem,

	// TITLE
	s45: 4.5rem,
	s50: 5.0rem,
	s51: 5.1rem,
	s58: 5.8rem,
	// banner Title primary
	s73: 7.3rem,
	// 73px in 1920px device
	s215: 21.5rem,
	// TITLE SHADOW
	s251: 25.1rem,
);

$title: (
	s58: (
		default: 5.8rem,
	),
	s51: (
		default: 5.1rem,
		pm: 4.8rem,
		ps: 4.2rem,
		tx: 3.8rem,
		ml: 3.4rem,
	),
	s45: (
		default: 4.5rem,
		pm: 4.2rem,
		tx: 4rem,
		mx: 3.8rem,
	),
	s41: (
		default: 4.1rem,
		pl: 3.4rem,
		tx: 3.2rem,
		mx: 3.2rem,
		ms: 2.8rem,
	),
	s37: (
		default: 3.7rem,
		pl: 3.2rem,
		tx: 3.2rem,
		mx: 3.2rem,
		ms: 2.8rem,
	),

	s31: (
		default: 3.1rem,
	),
	s26: (
		default: 2.6rem,
		pm: 2.4rem,
		ts: 2.2rem,
		ml: 2rem,
	),
	s21: (
		default: 2.1rem,
		tx: 2rem,
		mx: 1.9rem,
	),

	s18: (
		default: 1.8rem,
		tx: 1.7rem,
	),
);

// Cách sử dụng: map-get($media, ps);
// Hoặc là: @include rp(ps);
$media: (
	// personal computer
	px: 1920px,
	pl: 1680px,
	pm: 1440px,
	ps: 1336px,
	// tablet
	tx: 1280px,
	tl: 1024px,
	tm: 900px,
	ts: 768px,
	// mobile
	mx: 640px,
	ml: 540px,
	mm: 480px,
	ms: 384px,
	// mobile small
	sx: 360px,
	sl: 320px
);

// Cách sử dụng: Không tùy chỉnh trong đây (phần này mặc định)
$container-max-widths: (
	default: 1640px,
	pl: 1400px,
	pm: 1200px,
	tx: 960px,
	tl: 96%,
	// về talet thì sẽ width = 96%;
);

// Cách sử dụng: @include block(banner, margin, 20px); --> sử dụng biến s12
// tham số 1: tên key trong block
// tham số 2: thuộc tính cần gán
// tham số 3: (có thể bỏ qua) còn nếu có thì sẽ là
//				giá trị trái phải như của margin or padding
// ----> tương đương với margin: 1080px 20px;
$block: (
	header: (
		default: 183px,
		ps: 120px,
		tm: 100px,
	),
	banner: (
		default: 548px,
	),
	boxIndex: (
		default: 1080px,
		pl: 960px,
		ps: 320px,
		tx: 100px,
	),
	
	titleBackground: (
		default: 250px,
	),
);

// Cách sử dụng: @include space(s134, margin, 20px); --> sử dụng biến s12
// tham số 1: tên key trong block
// tham số 2: thuộc tính cần gán
// tham số 3: (có thể bỏ qua) còn nếu có thì sẽ là
//				giá trị trái phải như của margin or padding
// ----> tương đương với margin: 1080px 20px;
// y như block
// chú ý khi khoảng cách giống nhau nhưng media khác nhau thì có thể thêm đằng sau là -2 -3 (loại 2 loại 3)

$space: (
	s8:(
		default: 8px,
	),
	s17-breadcrumb: (
		default: 17px,
		ps: 16px,
		tx: 12px,
		mx: 9px,
	),
	s17: (
		default: 17px,
	),

	s24: (
		default: 24px,
		pm: 20px,
	),
	s34: (
		default: 34px,
		pm: 32px,
		tx: 24px,
		mx: 18px,
	),
	s36: (
		default: 36px,
	),
	s42: (
		default: 42px,
		
	),
	
	s48: (
		default: 48px,
		ps: 42px,
		tx: 38px,
		mx: 32px,
	),
	
	s50: (
		default: 50px,
	),

	s60: (
		default: 60px,
		pm: 56px,
		tx: 48px,
		mx: 36px,
	),

	s72: (
		default: 72px,
	),

	s90:(
		default: 90px,
		tx: 72px,
		mx: 42px,
		mm: 36px,
	),

	// Khoảng cách 2 section
	s134: (
		default: 134px,
		ps: 120px,
		tm: 100px,
	),
	s134-2: (
		default: 134px,
		ps: 121px,
		tl: 20px,
	),
	s140: (
		default: 140px,
		pl: 120px,
		pm: 100px,

		
	),
	

);


///////////////////// PAGE ////////////////////////////

// service
$serviceBox: (
	s52: (
		default: 52px,
		pl: 32px,
		pm: 24px,
		ps: 4%,
	),
	s90: (
		default: 90px,
		pl: 10%,
		tl: 15%,
		mx: 10%,
		ml: 2%,
	),
	s24: (
		default: 24px,
		pm: 0px,
		tx: 8px,
		tl: 4px,
		tm: 0,
	),
	s28: (
		default: 28px,
		pl: 24px,
		ps: 18px,
		tl: 12px
	),
);

@import "../_variables/banner";
@import "../_variables/footer";
@import "../_variables/header";
@import "../_variables/index";
@import "../_variables/service";
@import "../_variables/intro";
@import "../_variables/cuisine";
@import "../_variables/utilities";
@import "../_variables/library";
@import "../_variables/endow";
@import "../_variables/wedding.scss";
@import "../_variables/forum";
@import "../_variables/restroom";
@import "../_variables/popup";
@import "../_variables/intro-infor";
@import "../_variables/booking";
@import "../_variables/contact";

.wedding3{
    .wedding-menuIntro{
        
        .title-primary{
            display: flex;
            justify-content: center;
            // padding-bottom: 16px;

            @include space(s90, margin-top);
            @include space(s48, margin-bottom);
        }
        .content{
           position: relative;

           &__layout{
            position: absolute;
            z-index: -1;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;

           }

            &__desc{
                width: 100%;
                
                // transform: translate(-50%,-50%);
                
                // @include spacewedding3(s32,padding-top);
                // @include spacewedding3(s32,padding-bottom);
                // @include spacewedding3(s32,padding-left);
                // @include spacewedding3(s32,padding-right);
                .desc{
                    @include spacewedding3(s80,padding-top);
                    @include spacewedding3(s80,padding-bottom);

                    text-align: center;
                    
                    &-content{
                        background: white;
                        
                        padding: 12px;
                        
                        &>*{
                            border: 1px solid map-get($color, main );
                            @include spacewedding3(s72,padding-top);
                            @include spacewedding3(s72,padding-bottom);
                            @include spacewedding3(s104,padding-left);
                            @include spacewedding3(s104,padding-right);
                            
                            line-height: 1.6;

                            @include size(s18);
                            @include rp(tx){
                                line-height: 1.4;
                                font-size: 16px;
                            }

                            @include rp(mx){
                                line-height: 1.4;
                                font-size: 15px;
                            }

                            color: map-get($color, base);
                        }
                        
                    }

                
                }
            }
        }

    }
}
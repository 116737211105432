// // @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
// font-family: 'Open Sans', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');
// font-family: 'Judson', serif;

// @font-face {
//   font-family: "Lexend";
//   src: url("DroidSerif-Regular-webfont.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lexend";
//   src: url("DroidSerif-Italic-webfont.ttf") format("truetype");
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Lexend";
//   src: url("DroidSerif-Bold-webfont.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lexend";
//   src: url("DroidSerif-BoldItalic-webfont.ttf") format("truetype");
//   font-weight: bold;
//   font-style: italic;
// }
#intro-3__wrapper{
    .tour-title{
        width: 100%;
        text-align: center;
        // @include utilitiesBox(s90, margin-top);
        
        @include space(s90, margin-top);
        @include space(s48, margin-bottom);
    }
    .text-infor{
        // height: 100%;
        // display: flex;
        // flex-direction: column;

        &__time{
            color: map-get($color,dateTime );
            @include spaceIntro(m-r-10, margin-bottom);

        }
        &__title{
            // @include title(s26);
            // @include spaceIntro(s20, margin-top);
            // color: map-get($color,breadcrumb );
            // // @include spaceIntro(s30, margin-bottom);
            // @include family--second;
            // font-weight: bold;
            // flex: 1;

            @include title(s26);
            // @include title(s21);


            @include spaceIntro(s20, margin-top);
            color: map-get($color,breadcrumb );
            // @include spaceIntro(s30, margin-bottom);
            font-weight: bold;
            
            // @include family--second;
            @include family--primary;
            flex: 1;
        }
        &__decs{
            @include spaceIntro(s20, padding-top);
            @include spaceIntro(s20, margin-bottom);
            @include  p-line-clamp(4);
            p{
                text-align: justify;
            }
        }
        &__more{
            a{
                svg{
                    margin-left: 5px;
                }
            }
        }
    }
    .intro-3__section{
        &--nav{
            margin: auto;
            display: flex;
            justify-content: space-around;
            background: map-get($color, intro-bg-2 );
            @include family--second;
            font-weight: bold;
            @include spaceIntro(p14, padding);
            @include spaceIntro(m-b-90, margin-bottom);
            @include blockIntro-3(Intro-nav, width);
            @include blockIntro-3(Intro-nav-flex, flex);
            &-items{
                @include title(s26);
                color: map-get($color, bulletText );
                cursor: pointer;
                transition: 0.2s ease-in-out;
                &:hover{
                    color: map-get($color,main );
                }
            }
            .active{
                color: map-get($color, main );
            }
        }
        &--content{
            
            &-items.active{
                display: block;
            }
            &-items{
                animation: fadeEffect 1s;
            }
            @keyframes fadeEffect {
            from {opacity: 0;}
            to {opacity: 1;}
            }
            &-items{
                display: none;
                .content-intro{
                    @include rp(tx){
                        width: 100%;
                        flex: 0 0 100%;
                    }
                    margin: auto;
                    &__image{
                        width: 100%;
                        @include blockIntro-3(Intro-intro, height);
                        @include spaceIntro(m-b-90, margin-bottom);
                        position: relative;
                        &--overlay{
                            background: map-get($color, boxOverlayActive );
                            position: absolute;
                            top: 0;
                            left: 0;
                            @include spaceIntro(Intro-overlay, padding);
                            @include blockIntro-3(Intro-overlay, width);
                            .text-infor{
                                &__time{
                                    color: map-get($color,dateTime );
                                }
                                &__title{
                                    color: map-get($color,mainBg );
                                }
                                &__decs{
                                    color: map-get($color,dateTime );
                                    @include rp(mm){
                                        display: none;
                                     }
                                }
                                &__more{
                                    a{
                                        color: map-get($color,dateTime );
                                        path{
                                            fill: map-get($color,dateTime );
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                }
                .content-news{
                    @include rp(tx){
                        width: 100%;
                        flex: 0 0 100%;
                    }
                    margin: auto;
                   
                    &__items{
                        @include spaceIntro(p-r-72, margin-bottom);
                        // margin-right: auto;
                        width: calc(100% / 3);
                        @include rp(ts){
                            width: calc(100% / 2);
                        }
                        padding: 0 10px;
                        cursor: pointer;
                        &:hover{
                            .text-infor{
                                &__image{
                                
                                   img{
                                       transform: scale(1.15);
                                    //    transform: scale(1.15) rotate(5deg);
                                   }
                                }
                            }
                        }
                        &:nth-child(3n +1) {
                            
                            padding-right: 10px;
                            padding-left: 0px;
                            @include rp(ts){
                                padding: 0 10px;
                            }
                        }
                        &:nth-child(3n) {
                            padding-left: 10px;
                            padding-right: 0px;
                            @include rp(ts){
                                padding: 0 10px;
                            }
                        }
                        &:last-child{
                            justify-self: flex-start;
                        }
                        .text-infor{
                            &__image{
                                overflow: hidden;
                                img{
                                    transition: 0.3s ease-in-out;
                                }
                                width: 100%;
                                @include blockIntro-3(Intro-4, height);
                            }
                            &__title{
                                // @include  p-line-clamp(2);
                            }
                            &__decs{
                                @include rp(ml){
                                    @include  p-line-clamp(3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
#intro-2__wrapper{
    @include spaceIntro(p142, padding);
    background: map-get($color, intro-bg-2 );
    .container{
        @include rp(tx){
            flex:  0 0 100%;
            width: 100%;
        }
    }
    .intro-2__section{
        position: relative;
        
        .swiper-pagination{
            bottom: -25px;
            display: flex;
            justify-content: center;
            right: 0;
            .swiper-pagination-bullet{
                margin-left: 15px;
                
            }
            .swiper-pagination-bullet{
                @include rp(ml){
                    margin-left: 10px;
                }
            }
        } 
        .intro-2{
            height: 100%;
            position: unset;
        }
        .swiper-slide{
            width: 100%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            @include rp(tl){
                flex-direction: column;
                
            }
        }
        
        &--image{
           
            @include spaceIntro(p-r-72,padding-right);
            // @include blockIntro-2(Intro-1,height);
            // height: 100%;
            // max-height: 360px;
            .image{
                padding-top: 120%;
                position: relative;
                
                // height: 100%;
                width: 100%;
                // max-height: 360px;
                
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // max-height: 360px;
                }
            }
            @include rp(tl){
                position: absolute;
                flex: unset;
                width: 90%;
                height: 100%;
                .image{
                    height: 100%;
                    width: 100%;
                    padding-top: 0;
                }
                // height: 55vw;
                padding: 0;
            }
        }
        &--text{
            @include rp(tl){
                position:relative;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%,-50%);
                width: 90%;
                // height: 101%;

                background: map-get($color, bg-utilities-2 );
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 60px 5vw;
            }
            
            @include rp(ml){
                padding: 40px 5vw;
            }
            &-title{
                text-transform: uppercase;
                @include spaceIntro(m-b-30, margin-bottom);
                @include title(s26);
                a{
                    color: map-get($color, main );
                }
                // @include rp(pm){
                //     font-size: 1.3vw;
                // }
                // @include family--second;
                @include family--primary;
                
                font-weight: bold;
                color: map-get($color, main );
            }
           
            &-decs{
                // @include p-line-clamp(7);
                margin-top: 30px;
                color: map-get($color, base );
                p{
                    text-align: justify;
                    line-height: 1.4;
                }
                // @include rp(pm){
                //     @include p-line-clamp(4);
                // }
                @include rp(tl){
                    color: map-get($color, textInput );
                    margin-top: 12px;
                }
                // @include rp(ml){
                //     display: none;
                // }
                // @include rp(ps){
                //     font-size: 3.6rem;
                // }
                // @include rp(tx){
                //     font-size: 3.2rem;
                // }
                // @include rp(tl){
                //     font-size: 3rem;
                // }
                // @include rp(ts){
                //     font-size: 2.8rem;
                // }
                // @include rp(mm){
                //     font-size: 3rem;
                // }
                // @include rp(sl){
                //     font-size: 2.4rem;
                // }
            }
        }
    }
}
.overlay-banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(#000, .7), rgba(#000, 0));
  opacity: 0.5;
}

.overlay-restroom{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .15);
}

.path-restroom{
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: 0;
  left: 0;
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url('../assets/icons/path2.png');
  }
}
.overlay-gradient{
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(  rgba(0,0,0,0) , rgba(0,0,0,1));
      
    }
  
}
.overlay-cuisine{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: map-get($color, bg-cuisineOverlay );
}
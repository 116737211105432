// service


$libraryBox: (
  
    m-t-90: (
      default: 90px,
        pl: 82px ,
        pm: 54px ,
        ps: 44px,
      mm: 20px ,
    ),
    m-t-53: (
      default: 53px,
        pl: 43px ,
        pm: 33px ,
        ps: 23px,
        mm: 13px ,
    ),
    p-r-32:(
        default: 32px,
        pm: 22px,
        // ps: 20px ,
        mm: 7px ,
    ),
    p63: (
        default: 63px 0,
        pl: 42px 0,
        pm: 34px 0,
        ps: 8% 0,
        mm: 20px 0,
        ),
    
    p30: (
        default: 30px 0,
        pl: 20px 0,
        pm: 20px 0,
        ps: 3% 0 ,
        ),
    m-b-60:(
        default: 60px,
        pl: 50px,
        pm: 40px,
        ps: 30px,
        tl: 25px,
        ml: 15px,
    ),
    m70:(
        default: 70px auto,
        pl: 60px auto,
        pm: 50px auto,
        ps: 35px auto,
        mm: 20px auto,
    ),
    p10:(
      default: 0 10px,
        // pl: 60px auto,
        // pm: 50px auto,
        // ps: 35px auto,
        // mm: 20px auto,
    )
);

$blockLibrary: (
	library-1: (
		default: 324px,
		pl: 17vw,
		// pm: 7vw,
		// tx: 7vw,
		// // tl: 220px,
    // tm: 224px,
    ts: 204px,
    mx: 164px,
    ml: 124px,
    mm: 104px,
    sx: 84px,
    ms: 180px,
    
	),
  library-video-top: (
		default: 502px,
		pl: 402px,
		pm: 382px,
		tx: 302px,
		tl: 282px,
    tm: 402px,
    ts: 304px,
    ml: 204px, 
    mm: 164px,
    // mm: 100%,
	),
  library-video-top-big: (
		default: 813px,
		pl: 713px,
		pm: 613px,
		tx: 60%,
    tm: 100%,
	),
  library-video-top-small: (
		default: 502px,
		pl: 412px,
		pm: 352px,
		tx: 37%,
		// tl: 220px,
    // tm: 100%,
    // ts: 204px,
    // mx: 164px,
    // mm: 100%,
	),
  library-video-bottom: (
    default: 228px,
		// pl: 402px,
		pm: 188px,
		tx: 168px,
		tl: 158px,
    tm: 148px,
    ts: 128px,
    mx: 108px,
    ml: 88px, 
    ms: 54px,
  )
);


@mixin libraryBox($key, $name, $side: '-1'){
  @each $idx, $val in map-get($libraryBox, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}

@mixin blockLibrary($key, $name, $side: '-1'){
  @each $idx, $val in map-get($blockLibrary, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
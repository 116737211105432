.wedding4{
    .wedding__setMenu{
        background: map-get($color, intro-bg-2 );
        .box-pagination{
            @include spacewedding4(s108, padding-bottom);
        }
        .wedding__list{
            @include spacewedding4(s108, padding-top);
            padding-bottom: 16px;
            .card-margin{
                box-shadow: 0px 3px 6px rgba(#000, .16);
                background: map-get($color, mainBg );
                // width: 30%;
                @include rp(ml){
                    margin: 0 32px;
                }
                
            }
            .content-before{
                width: 100%;
                position: relative;
                &::before, &::after{
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 60px;
                    
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background: url(../assets/images/page/wedding/w4-img-1.png);
                }
                &::before{
                    top: -14px;
                    left: 50%;
                    transform: translate(-50%,-100%);
                }
                &::after{
                    bottom: -14px;
                    transform: rotate(180deg) translateY(-100%);
                }
                // @include spacewedding4(s160,margin,0);
                
                @include spacewedding4(s32, padding,0);
                background: map-get($color, main );

                &__title{
                    text-align: center;
                    @include spacewedding4(s16,margin-bottom);
                    &>*{
                        color: map-get($color, mainBg );
                        @include size(s26);
                        @include bold;
                        
                    }
                }
                &__price{
                    text-align: center;
                    @include spacewedding4(s16,margin-bottom);
                    &>*{
                        color: map-get($color, mainBg );
                        @include size(s58);
                        @include rp(mx){
                            font-size: 46px;
                        }
                        @include bold;
                        
                    }
                }
               &__desc{
                   display: flex;
                    justify-content: center;

                &>*{
                    color: map-get($color, mainBg );
                    @include size(s18);
                    @include bold;
                    
                }
                &--table{
                    @include spacewedding4(s20, margin-left);
                    position: relative;
                    &::after{
                        position: absolute;
                        content: "";
                        top: 10%;
                        right: 130%;
                        width: 1px;
                        height: 16px;
                        background: map-get($color, mainBg );
                    }
                }
               }
            }
            .content-after{
                @include spacewedding4(s80, padding-top);
                @include spacewedding4(s80, padding-bottom);
                @include spacewedding4(s40, padding-left);
                @include spacewedding4(s40, padding-right);
                &__title{
                    margin-bottom: 8px;
                    &>*{
                        color: map-get($color, main );
                        @include size(s26);
                        @include bold;
                        
                    }
                }
                &__desc{
                    @include spacewedding4(s80, margin-bottom);
                    display: flex;
                    position: relative;
                    z-index: -1;
                    &::after{
                        position: absolute;
                        content: "";
                        bottom: -100%;
                        left: 0%;
                        width: 100%;
                        height: 1px;
                        background: map-get($color, base );
                    }
                 &>*{
                     color: map-get($color, base );
                     @include size(s26);
                     @include bold;
                     
                 }
                 &--table{
                     @include spacewedding4(s20, margin-left);
                     position: relative;
                     
                     &::after{
                         position: absolute;
                         content: "";
                         top: 10%;
                         right: 120%;
                         width: 1px;
                         height: 26px;
                         background: map-get($color, base );
                     }
                 }
                }
                &__list{
                    @include spacewedding4(leftList, padding-left);
                }
                &__items{
                    @include spacewedding4(s16, margin-bottom);
                    &>*{
                        @include size(s18);
                        color: map-get($color, base );

                    }
                }
            }
            // panigation
            
            //hieu ung

            .wedding__card{
                // margin:  8px 8px;
                height: 100%;
                // min-height: 520px;
                @include blockwedding2(hBoxWedding4, min-height);
                
                position: relative;
                transform-style: preserve-3d;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover{
                    .content-after::before{
                        transform: scaleX(1);
                        transition: transform 0.3s ease-in-out;
                        z-index: -1;
                        transform-origin:  left;
                        opacity: 1;
                    }
                    .content-after{
                        transform: scaleX(1);
                        transition: transform 0.3s ease-in-out;
                        z-index: 1;
                        transition-delay: 0.1s;
                        transform-origin:  left;
                        opacity: 1;

                    }
                    .content-before{
                        z-index: -1;
                    }
                }
            }
            .content-after{
                
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
                transform: scaleX(0);
                opacity: 0;
                transition: transform 0.3s ease-in-out;
            }
            .content-after::before{
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: map-get($color, mainBg );
                transform: scaleX(0);
                transition: transform 0.3s ease-in-out;
                transform-origin: right;
                transition-delay: 0.5s;
                opacity: 0;
            }
            
        }
    }
}
#intro-1__wrapper{
    @include spaceIntro(p142, padding);
    .intro-1__section{
        // position: relative;
        display: flex;
        align-items: center;
        @include rp(tm){
            flex-direction: column;
        }
        &--image{
            position: relative;
            @include rp(tm){
                flex: unset;
                width: 90%;
                margin-bottom: 30px;
                // @include spaceIntro(Intro-1, height);
                
            }
            .swiper-pagination{
                bottom: -25px;
                display: flex;
                justify-content: flex-end;
                right: 0;
                .swiper-pagination-bullet{
                    margin-left: 15px;
                    @include rp(ml){
                        margin-left: 10px;
                    }
                }
                @include rp(ml){
                    bottom: -15px;
                }
            }
            @include blockIntro-1(Intro-1, height);
            .intro-1{
                position: unset;
                height: 100%;

                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: calc(50% - 8px) calc(50% - 8px);

                
                @include rp(pm){
                    // grid-template-rows: ;
                }

                gap: 16px;

                .image__pic:nth-child(2){
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 2;
                }
                .image__pic:nth-child(3){
                    grid-row: 2 / span 1;
                    grid-column: 1 / span 1;
                }
                .image__pic:nth-child(1){
                    grid-row: 1 / span 1;
                    grid-column: 1 / span 1;
                }

                .image{
                    // @include rp(pm){
                    //     width: 49%;
                    // }
                    
                    // height: 100%;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: space-between;
    
                    &__pic{
                        // @include blockIntro-1(Intro-3, height);
                        // @include blockIntro-1(Intro-2, width);

                        // &:first-child{
                        //     position: relative;
                        //     &::after{
                        //         position: absolute;
                        //         top: 0;
                        //         left: 0;
                        //         width: 100%;
                        //         height: 100%;
                        //         content: "";
                        //         background: map-get($color, intro-overlay );
                        //     }
                        //     &::before{
                        //         position: absolute;
                        //         top: 50%;
                        //         left: 50%;
                        //         transform: translate(-50%, -50%);
                        //         width: 80%;
                        //         height: 80%;
                        //         content: "";
                        //         background: none;
                        //         border: solid 1px map-get($color, mainBg );
                        //         z-index: 1;
                        //     }
                        // }
                        // @include rp(pm){
                        //     height: 49%;
                        // }
                    }
                    
                }
            }
            .swiper-wrapper{
                height: 100%;
                .swiper-slide{
                    display: flex;
                    justify-content: space-between;
                    
                }
            }

            
        }
        &--text{
            @include spaceIntro(p-l-53, padding-left);
            &-headtitle{
                text-transform: uppercase;
                @include spaceIntro(s20, margin-bottom);
                @include title(s26);
                font-weight: bold;
                color: map-get($color, base );
            }
            &-title{
                @include spaceIntro(m-b-60, margin-bottom);
                @include title(s51);
                a{

                    color: map-get($color, main );
                }
                
                @include family--primary;
                font-weight: bold;
                color: map-get($color, main );
                
            }
            &-decs{
                // @include spaceIntro(m-b-60, margin-bottom);
                color: map-get($color, base );
                p{
                    @include spaceIntro(s20, margin-bottom);
                    text-align: justify;
                    line-height: 1.4;
                }
            }
            @include rp(tm){
                flex: unset;
                width: 90%;
            }
        }
    }
}
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;
 
}

.enableSrcoll{
  height: 100vh;
  overflow: hidden;
}
.header {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

  &-top {
    @include blockHeader(top, height);
    background-color: map-get($color, main);

    .container {
      height: 100%;
    }

    &__content {
      width: 100%;
      height: 100%;

      .content {
        height: 100%;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &-info {
          display: flex;
          flex-direction: row;

          &__item {
            display: flex;
            flex-direction: row;
            line-height: 2rem;

            & > * {
              @include bold;
              &:not(:first-child) {
                margin-left: 12px;

                @include rp(tl) {
                  margin-left: 4px;
                }
              }
              font-size: 1.5rem;
              @include rp(tl) {
                font-size: 1.3rem;
              }
            }

            &:not(:first-child) {
              padding-left: 16px;
              @include rp(tl) {
                padding-left: 8px;
              }

              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                height: 100%;
                transform: translate(-50%, -50%);
                width: 1px;

                background-color: map-get($color, mainBg);

                @include rp(ml){
                  width: 0;
                  opacity: 0;
                  visibility: hidden;
                  height: 0;
                  display: none;
                }
              }
            }
            &:not(:last-child) {
              padding-right: 16px;
              @include rp(tl) {
                padding-right: 8px;
              }
            }

            &-icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &-span {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            // svg{
            //   path{
            //     stroke: map-get($color, mainBg);
            //   }
            // }
          }

          &__phone,
          &__email {
            @include rp(ml) {
              display: none;
            }
          }
        }

        span,
        p {
          color: map-get($color, mainBg);
        }

        &-order {
          margin-left: 36px;
          @include rp(tl) {
            margin-left: 18px;
          }

          svg,
          img {
            margin-left: 6px;
            @include rp(tl) {
              margin-left: 4px;
            }
          }

          &__link {
            display: flex;
            justify-content: center;
            align-items: center;

            max-height: 100%;
            @include bold;
            @include uppercase;

            font-size: 1.8rem;
            @include rp(tl) {
              font-size: 1.6rem;
            }
            @include rp(ts) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  &-main {
    // height: 123px;
    @include blockHeader(main, height);
    background-color: map-get($color, mainBg);

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      @include rp(tl){
        position: initial;
      }
    }

    &__logo {
      // width: 186px;
      display: block;
      @include blockHeader(logoWidth, width);

      height: 100%;
      img {
        object-fit: contain;
        object-position: left;
      }
    }

    &__nav {
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      height: 100%;

      &-item {
        @include uppercase;

        & > * {
          @include bold;
          @include size(s18);
          color: map-get($color, main);
          white-space: nowrap;

          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: calc(100% + 6px);
            left: 50%;
            width: 0%;
            opacity: 0;
            height: 3px;
            background-color: map-get($color, underline);
            transition: 0.3s all ease;
          }
        }



        &.active {
          
          &>*{
            &::after {
              width: 100%;
              left: 0%;
              opacity: 1;
            }
          }
        }

        &:hover {
          &>*{
            &::after {
              width: 100%;
              left: 0%;
              opacity: 1;
            }
          }
        }

        @include rp(ps) {
          // margin: 12px 0;
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 12px 0px;
          
          & > * {
            display: block;
            padding-bottom: 4px;
            &::after {
              top: calc(100% - 3px);
            }
          }
          &:not(:first-child){
            // border-top: 1px solid rgba(0, 0, 0, 0.16);
           
            
          }
        }
      }
      &-item + &-item {
        @include spaceHeader(s42, margin-left);

        @include rp(ps) {
          margin-left: 0;
        }
      }
      &-item{
        position: relative;
        @include rp(ps){

          &:not(:first-child)::after{
            top: 0%;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            content: "";
            background: rgba(0, 0, 0, 0.16);
            width: 50% ;
            height: 1px;
          }
        }
      }
      @include rp(ps) {
        position: absolute;
        top: calc(100% - 1px);
        right: 0;

        height: auto;
        
        // display: flex;
        justify-content: initial;
        align-items: center;
        flex-direction: column;

        display: none;

        &.active{
          display: inherit;
          animation: fadeInUp .16s ease forwards;
        }

        min-width: 300px;
        @include rp(sl){
          min-width: 100%;
          width: 100%;
          max-width: 100%;
        }

        background-color: map-get($color, mainBg);
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);

        max-height: calc(100vh - 320px);
        overflow-y: auto;
      }

      @include rp(ml){
        width: 100%;
      }
    }
    &__navMobile {
      list-style-type: none;
      display: none;
      justify-content: flex-end;
      align-items: center;

      height: 100%;

      &-item {
        @include uppercase;

        & > * {
          @include bold;
          @include size(s18);
          color: map-get($color, main);
          white-space: nowrap;

          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: calc(100% + 6px);
            left: 50%;
            width: 0%;
            opacity: 0;
            height: 3px;
            background-color: map-get($color, underline);
            transition: 0.3s all ease;
          }
        }



        &.active {
          
          &>*{
            &::after {
              width: 100%;
              left: 0%;
              opacity: 1;
            }
          }
        }

        &:hover {
          &>*{
            &::after {
              width: 100%;
              left: 0%;
              opacity: 1;
            }
          }
        }

        @include rp(ps) {
          // margin: 12px 0;
          width: 20%;
          display: flex;
          justify-content: center;
          padding: 40px 0px;
          
          & > * {
            display: block;
            padding-bottom: 4px;
            
            color: map-get($color, mainBg );
            
            &::after {
              top: calc(100% - 3px);
            }
          }
          &:not(:first-child){
            // border-top: 1px solid rgba(0, 0, 0, 0.16);
           
            
          }
        }
        @include rp(mx){
          width: 100%;
          padding: 25px 60px;
          justify-content: flex-start;
        }
        @include rp(sx){
          padding: 25px 40px;
        }
      }
      &-item + &-item {
        @include spaceHeader(s42, margin-left);

        @include rp(ps) {
          margin-left: 0;
         
        }
      }
      &-item{
        position: relative;
        @include rp(mx){
          &:not(:first-child)::after{
            top: 0%;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            content: "";
            background: rgba(255, 255, 255, 0.16);
            width: 85% ;
            height: 1px;
          } 
        }
        
      }
      @include rp(ps) {
        position: absolute;
        top: 100%;
        right: 0;
        
        height: auto;
        
        // display: flex;
        // justify-content: initial;
        // align-items: center;
        flex-wrap: wrap;

        display: none;
       
          
        
        
          &:not(:first-child)::after{
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            content: "";
            background: rgba(255, 255, 255, 0.16);
            width: 85% ;
            height: 1px;
          }
          
          @include rp(mx){
            &:not(:first-child)::after{
              width: 0%;
              height: 0%;
            }
            
          }
        
        &.active{
          display: flex;
          animation: fadeRightTo .3s ease forwards;
        }

        min-width: 100%;
        @include rp(mx){
          min-width: 100%;
          width: 100%;
          max-width: 100%;
          justify-content: flex-start;
          flex-direction: column;
          flex-wrap: nowrap;
        }
        
        background-color: map-get($color, main);
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);

        max-height: calc(100vh - 320px);
        overflow-y: auto;
      }

      @include rp(ml){
        width: 100%;
      }
    }
    &__button {
      width: 42px;
      height: 42px;
      border-radius: 3px;

      // background-color: rgba(map-get($color, base), 0.4);

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      @include rp(ps) {
        display: inherit;
      }

      &-border {

        width: 80%;
        height: 2px;
        border-radius: 1px;
        background-color: map-get($color, main);
        transition: 0.3s all ease;

        position: relative;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: -12px;
          left: 0;
          bottom: initial;
          right: initial;
          width: 100%;
          height: 2px;
          background-color: map-get($color, main);

          transition: 0.3s all ease;
        }

        &::after {
          top: initial;
          bottom: -12px;
        }
      }
      display: none;
      &.show {
        display: inherit;
      }
      &.active {
        .header-main__button-border {
          height: 0;
          background-color: transparent;

          &::before,
          &::after {
            top: 50%;
            bottom: initial;
            width: 100%;
          }
          &::before {
            transform: translateY(-50%) rotate(45deg);
          }
          &::after {
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
  }
}


.content-order__popup{
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  min-width: 676px;
  @include rp(ts){
    min-width: 100%;
  }
  height: 100%;
  transform: translateX(100%);
  transition: .2s all ease-in-out;
  display: none;

  &.active{
    display: block;
    animation: moveCurr .2s ease-in-out forwards;

    .content-order__popup-overlay{
      animation: visible .16s ease-in-out forwards .2s;
    }
  }

  &-overlay{
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: map-get($color, boxOverlay);
    z-index: -1;
    visibility: hidden;
  }
  &-wrapper{
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #F7F8F7;

    position: relative;

    .close{
      position: absolute;

      top: 36px;
      @include rp(ts){
        top: 24px;
      }
      right: 36px;

      width: 42px;
      height: 42px;
      border-radius: 3px;

      // background-color: rgba(map-get($color, base), 0.4);

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      // background-color: map-get($color, main);
      &:hover{
        background-color: rgba(map-get($color, main),.7);

        &::after, &::before{
          background-color: white;
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: initial;
        
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        bottom: initial;
        width: 70%;

        height: 2px;
        background-color: map-get($color, base);
        transition: 0.3s all ease;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .content-popup{
      
      span{
        color: initial;
      }
      img{
        margin: 0;
      }

      &__title{
        padding: 36px;
        @include rp(ts){
          padding: 24px 36px;
        }
        line-height: 42px;

        position: relative;

        &>*{
          @include regular;
          color: map-get($color, main);
          @include uppercase;
          font-size: 2.5rem;
        }

        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 7px;
          background-color: map-get($color, main);
        }

        pointer-events: none;
      }
      &-form{
        margin-top: 60px;
        margin-bottom: 60px;
        @include rp(ps){
          margin-top: 48px;
          margin-bottom: 48px;
        }
        @include rp(ts){
          margin-top: 36px;
          margin-bottom: 36px;
        }

        padding: 0 36px;

        form{
          &>*{
            &:not(:first-child){
              margin-top: 12px;
            }
          }
        }

        .group-input{
          display: flex;
          flex-direction: column;
          height: 86px;
          @include rp(ts){
            height: 72px;
          }
          padding: 12px;
          background-color: white;
          border: 1px solid map-get($color, base);
          
          label{
            width: 100%;
            height: 100%;
            @include uppercase;
            color: #BCBCBC;

            span{
              color: inherit;
              // color: map-get($color, base);
            }
            &.label-input{
              cursor: pointer;
            }
          }


          input{
            border: none;
            outline: none;
            background: transparent;
            width: 100%;
            height: 100%;
            border-bottom: 1px solid map-get($color, base);
            padding-bottom: 5px;

            transition: .16s all ease-in-out;
            position: relative;

            &:focus{
              border-bottom: 1px solid transparent;
            }
            
            &:focus ~ .input-content__border{
              transform: translateX(0);
              width: 100%;
              transform-origin: left;
            }

            &.error{
              border-bottom: 1px solid map-get($color, error);
            }
            &.success{
              border-bottom: 1px solid map-get($color, success);
            }
          }

          .input-content{
            // height: 100%;
            // width: 100%;
            position: relative;
            cursor: pointer;

            &__img{
              position: absolute;
              top: 0; right: 0;
              object-fit: contain;
              margin: 0 !important;
              width: auto;
              height: 100%;
              padding-bottom: 4px;
              pointer-events: none;

              img{
                object-fit: contain;
              }
            }

            &__border{
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0%;
              height: 2px;
              background-color: map-get($color, main);
              transition: .7s all ease-in-out;
              // transform: translateX(-100%);
            }
          }
        }

        .order-option{
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          margin-top: 0px !important;
          margin-left: -12px;
          margin-right: -12px;
          margin-bottom: 0;

          &-item{
            width: 33.3333%;
            padding: 12px 12px 0 12px;

            @include rp(ml){
              min-width: 50%;
              flex: 1;
            }
            @include rp(ms){
              min-width: 100%;
              flex: 1;
            }

            .input-content{
              &__value{
                display: block;
                width: 100%;
                color: black;
                font-size: 2.5rem;
                span{
                  color: inherit;
                  font-size: inherit;
                }
              }
            }

            position: relative;
            .group-option{
              &-list{
                position: absolute;
                top: 100%;
                left: 0;
                width: calc(100% - 24px);
                background-color: white;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
              
                margin: 0 12px;
  
                overflow: auto;
                z-index: 11111;

                max-height: 180px;
                overflow: auto;

                display: none;

                &.active{
                  display: block;
                }
              }
  
              &-item{
                padding: 12px;
                cursor: pointer;
                &.active{
                  color: white;
                  background-color: map-get($color, main);
                  span{
                    color: inherit;
                  }
                  pointer-events: none;
                }

                transition: .16s all ease-in;
                // &.active:hover{
                //   // color: white;
                //   // background-color: rgba(map-get($color, main),.7);
                //   // span{
                //   //   color: inherit;
                //   // }
                // }
                &:hover{
                  color: white;
                  background-color: rgba(map-get($color, main),.7);
                  span{
                    color: inherit;
                  }
                }
              }
            }
            
          }
        }

        .order-btn{
          margin-top: 42px !important;
          @include rp(ts){
            margin-top: 36px !important;
          }

          display: flex;
          justify-content: center;
          align-items: center;

          button{
            outline: 0;
            border: none;
            background: transparent;

            font-size: 3rem;
            background-color: map-get($color, base);
            color: white;
            @include uppercase;

            padding: 10px 30px;

            cursor: pointer;

            transition: .16s all ease;
            &:hover{
              background-color: map-get($color, main);
            }
          }
        }

        #saleId{
          @include uppercase;
        }

        .infor-order{
          display: block;
          font-size: 13px;
          color: map-get($color, base);
          &.error{
            color: map-get($color, error);
          }
          &.success{
            color: map-get($color, success);
          }
          span, p{
            font-size: inherit;
            color: inherit;
          }
        }
      }
    }
  }
}

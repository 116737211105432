.breadcrumb{
  // margin-top: 160px;
  // margin-bottom: 56px;
  // @include spaceUltraBig(margin-top);
  // @include spaceMedium(margin-bottom);

  &-list{
    display: flex;
    list-style-type: none;
  }
  &-item + &-item{
    margin-left: 56px;
    position: relative;
  }

  &-item{
    // font-size: map-get($size, breadcrumb);
    &__link{
      // color: map-get($color, primary);
    }
    &__icon{
      // color: map-get($color, primary);

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(56px / 2 * (-1));

      path{
        // stroke: map-get($color, primary) !important;
      }
    }
  }
}
.restroom-popup,
.service-popup,
.cuisine-popup,
.image-popup,
.video-popup,
.travel-popup,
.job-popup,
.wedding-popup,
.hall-popup,
.total-popup{
  width: 100%;
  max-width: 1924px;
  overflow-x: hidden;

  height: 100vh;
  max-height: 1080px;
  overflow-y: hidden;

  background-color: map-get($color, bgPopup);
 

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  .swiper-wrapper,
  .swiper-container{
    width: 100%;
  }

  &--overlay{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(90,90,90, .7);
    z-index: 100000;

    display: flex;
    justify-content: center;
    align-items: center;

    display: none;

    &.show{
      display: flex;

      animation: scaleZoom .3s ease forwards;
    }
  }

  .popup{
    display: block;
    width: 100%;
    height: 100%;
    &-close{

      position: absolute;
      right: 0;
      top: 0;
      background-color: map-get($color, close-popup);
      z-index: 100;
      
      cursor: pointer;
      width: 46px;
      height: 46px;
      border-bottom-left-radius: 10px;
      @media (max-width: map-get($media, tl)){
        $wh: 42px;
        width: $wh;
        height: $wh;
        border-bottom-left-radius: 8px;
      }
      @media (max-width: map-get($media, mx)){
        $wh: 36px;
        width: $wh;
        height: $wh;
        border-bottom-left-radius: 6px;
      }

      &::before,
      &::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 80%;
        height: 4px;
        @media (max-width: map-get($media, tl)){
          width: 75%;
          height: 3px;
        }
        @media (max-width: map-get($media, mx)){
          width: 70%;
          height: 2px;
        }
        background-color: map-get($color, close-text-popup);
        border-radius: 10px;
      }
      &::before{
        transform: translate(-50%,-50%) rotate(45deg);
      }
      &::after{
        transform: translate(-50%,-50%) rotate(-45deg);
      }
    }
    &-wrapper{
      display: block;
      width: 100%;
      height: 100%;

      .container{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--bg{
        display: flex;
        justify-content: center;
        flex-direction: row;
        z-index: 1;

        @include rp(tl){
          flex-direction: column;
          justify-content: flex-start;
        }

        background-color: white;
        @media (min-height: 800px) {
          min-height: 70%;
          max-height: 780px;
        }
        min-height: 60%;
        
        max-height: calc(90% - 30px);

        @include rp(tl){
          overflow-y: auto;
        }
        
        padding: 16px;
        @include rp(ts){
          padding: 12px;
        }
        @include rp(ml){
          padding: 8px;
        }
      }

      // restroom
      &__imgs{
        overflow: auto;
        max-height: 100%;

        @include rp(tl){
          max-height: initial;
          overflow-y: initial;
        }

        &-swiper{
          position: sticky;
          // height: 538px;
          @include popup(imgSwiper, height);

          .swiper-slide{
            img{
              transition: .2s all ease;
              &.scale{
                transform: scale(3);
              }
              cursor: zoom-in;
            }
          }
    
          .swiper-button{
            &-next,
            &-prev{
              width: 43px;
              height: 43px;
              @media (max-width: map-get($media, tl)){
                $wh: 36px;
                width: $wh;
                height: $wh;
              }
        
              @media (max-width: map-get($media, mx)){
                $wh: 30px;
                width: $wh;
                height: $wh;
              }
    
              border-radius: 1000px;
              background-color: map-get($color, buttonSwiper-2);
            
              svg{
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 12px;
                @include rp(tl){
                  width: 8px;
                }
              }
            }
    
            &-next{
              left: calc(50% + 32px);
              bottom: 16px;
              top: auto;
              right: auto;
              transform: translateX(-50%);
    
              &::after{
                content: "";
              }
            }
            &-prev{
              left: calc(50% - 32px);
              bottom: 16px;
              top: auto;
              right: auto;
              transform: translateX(-50%);
              svg{
                transform: rotate(180deg);
              }
              &::after{
                content: "";
              }
            }
        
          }
        }
        &-thumbs{
          // height: 184px;
          @include popup(imgThumbs, height);

          position: relative;
          margin-top: 12px;
          // height: 30%;

          .swiper-slide{
            position: relative;
    
            &::after{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #FFF;
              opacity: .5;
              transition: .16s all ease;
            }
            &.active{
              &::after{
                opacity: 0;
              }
            }
          }

          .swiper-button{
            &-next,
            &-prev{
              width: 43px;
              height: 100%;
              @media (max-width: map-get($media, tl)){
                $wh: 36px;
                width: $wh;
                // height: $wh;
              }
        
              @media (max-width: map-get($media, mx)){
                $wh: 30px;
                width: $wh;
                // height: $wh;
              }
    
              // border-radius: 1000px;
              background-color: map-get($color, buttonSwiper-2);
            
              svg{
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 12px;
                @include rp(tl){
                  width: 8px;
                }
              }
            }
    
            &-next{
              left: auto;
              bottom: auto;
              right: 0;
              top: 0;
    
              transform: none;
              margin: 0;
              &::after{
                content: "";
              }
            }
            &-prev{
              left: 0;
              top: 0;
              right: auto;
              bottom: auto;
              transform: none;
              margin: 0;


              svg{
                transform: rotate(180deg);
              }
              &::after{
                content: "";
              }
            }
        
          }
        }
        &-slide{
          height: 100%;
        }
      }
      &__content{ 
        max-height: 100%;
        overflow-y: auto;

        @include rp(tl){
          max-height: initial;
          overflow-y: initial;
        }

        // padding: 0 28px;
        @include popup(contentPopup, padding);

        &::-webkit-scrollbar {
          width: 13px;

          @include rp(tx){
            width: 8px;
          }
          @include rp(mx){
            width: 6px;
          }
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          background: map-get($color, track); 
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: map-get($color, thumb);
          border-radius: 8px;
          @include rp(tx){
            border-radius: 3px;
          }
          @include rp(mx){
            border-radius: 2px;
          }
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: map-get($color, thumbHover); 
        }

        @include rp(tl){
          display: grid;
          grid-template-columns: auto auto;
          @include rp(ts){
            grid-template-columns: auto;
          }
          &>*{
            &:first-child{
              grid-column: 1 / span 2;
              @include rp(ts){
                grid-column: inherit;
              }
            }
          }
        }

        &-item{
          h1, h2, h3, h4, h5, h6{
            color: map-get($color, main);
            @include bold;
          }
          // h1{
          //   @include title(s41);
          // }
          h2{
            @include title(s26);
          }

          p{
            @include regular;
            line-height: 1.6;
            @include size(s18);
            color: map-get($color, base);
          }

          ul{
            // padding-left: 28px;
            @include popup(contentUlPopup, padding-left);

            li{
              &::marker{
                @include size(s21);
                color: map-get($color, base);
              }
            }
            li + li{
              // margin-top: 12px;
              @include popup(liPopup, margin-top);
            }
          }

          &:first-child{
            // margin-top: 24px;
            @include popup(itemPopup, margin-top);
            h1:first-child,
            h2:first-child,
            h3:first-child,
            h4:first-child,
            h5:first-child,
            h6:first-child{
              @include title(s41);
              @include family--primary;
              position: relative;
              padding-bottom: 8px;
              &::after{
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: map-get($color, main);
              }
            }
          }
          &:not(:first-child){
            position: relative;
            // margin-top: 24px;
            // padding-top: 24px;
            @include popup(itemPopup, margin-top);
            @include popup(itemPopup, padding-top);

            &::after{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 1px;
              border-bottom: 1px solid map-get($color, base);
            }
            
          }
          &>*:not(:first-child){
            // margin-top: 24px;
            @include popup(itemPopup, margin-top);
          }
        }
      }

      // tour service
      &__tour{
        @include popup(itemPopup, margin-top);
        @include popup(itemPopup, margin-bottom);
        // padding: 0 56px;
        @include popup(serviceContent, padding);

        ul{
          padding-left: 20px;
          li{
            @include regular;
            line-height: 1.4;
            @include size(s18);
            color: map-get($color, base);
  
            letter-spacing: 1.4px;
          }
        }


        h1, h2, h3, h4, h5, h6{
          color: map-get($color, main);
          @include bold;
        }

        h3{
          @include title(s26);
        }

        &{
          // margin-top: 24px;
          h1:first-child,
          h2:first-child,
          h3:first-child,
          h4:first-child,
          h5:first-child,
          h6:first-child{
            @include family--primary;
            position: relative;
            padding-bottom: 8px;
            &::after{
              content: "";
              position: absolute;
              top: 100%;
              left: 0;
              width: 64px;
              height: 5px;
              background-color: map-get($color, underline);
            }
          }

          h3:first-child{
            @include title(s41);
          }

          h1:not(:first-child),
          h2:not(:first-child),
          h3:not(:first-child),
          h4:not(:first-child),
          h5:not(:first-child),
          h6:not(:first-child){
            @include popup(itemPopup, margin-top);
            @include popup(itemPopup, padding-top);
            position: relative;

            &::after{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 1px;
              border-bottom: 1px solid map-get($color, base);
            }
          }

          h1:not(:first-child)+p,
          h2:not(:first-child)+p,
          h3:not(:first-child)+p,
          h4:not(:first-child)+p,
          h5:not(:first-child)+p,
          h6:not(:first-child)+p{
            margin-top: 28px;
          }
        }

        &>*{
          &:nth-child(2){
            margin-top: 28px;
          }  
        }

        
        p, span, a{
          @include regular;
          line-height: 1.4;
          @include size(s18);
          color: map-get($color, base);

          letter-spacing: 1.4px;
        }

        max-height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 13px;

          @include rp(tx){
            width: 8px;
          }
          @include rp(mx){
            width: 6px;
          }
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          background: map-get($color, track); 
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: map-get($color, thumb);
          border-radius: 8px;
          @include rp(tx){
            border-radius: 3px;
          }
          @include rp(mx){
            border-radius: 2px;
          }
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: map-get($color, thumbHover); 
        }
      }

      // cuisine
      &__cuisine{
        overflow: auto;

        h1, h2, h3, h4, h5, h6{
          color: map-get($color, main);
          @include bold;
          @include family--primary;
        }

        h3{
          @include title(s41);

          padding-top: 32px;
          padding-bottom: 32px;

          @include rp(tl){
            padding-top: 24px;
            padding-bottom: 24px;
          }
          @include rp(mx){
            padding-top: 18px;
            padding-bottom: 18px;
          }

          text-align: center;
        }

        img{
          display: block;
          width: 100%;
          height: auto;
          object-fit: contain;
        }

        p{
          line-height: 1.6;
          letter-spacing: .12rem;
        }

        &::-webkit-scrollbar {
          width: 13px;

          @include rp(tx){
            width: 8px;
          }
          @include rp(mx){
            width: 6px;
          }
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          background: map-get($color, track); 
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: map-get($color, thumb);
          border-radius: 8px;
          @include rp(tx){
            border-radius: 3px;
          }
          @include rp(mx){
            border-radius: 2px;
          }
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: map-get($color, thumbHover); 
        }


      }

      // image library
      &__image{
        overflow: auto;
        max-height: 100%;

        display: none;
        &.active{
          display: block;
        }


        @include rp(tl){
          max-height: initial;
          overflow-y: initial;
        }

        &-swiper{
          // height: 538px;
          @include popup(imgSwiper, height);

          .swiper-slide{
            img{
              transition: .2s all ease;
              &.scale{
                transform: scale(3);
              }
              cursor: zoom-in;
            }
          }
    
          .swiper-button{
            &-next,
            &-prev{
              width: 43px;
              height: 43px;
              @media (max-width: map-get($media, tl)){
                $wh: 36px;
                width: $wh;
                height: $wh;
              }
        
              @media (max-width: map-get($media, mx)){
                $wh: 30px;
                width: $wh;
                height: $wh;
              }
    
              border-radius: 1000px;
              background-color: map-get($color, buttonSwiper-2);
            
              svg{
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 12px;
                @include rp(tl){
                  width: 8px;
                }
              }
            }
    
            &-next{
              left: calc(50% + 32px);
              bottom: 16px;
              top: auto;
              right: auto;
              transform: translateX(-50%);
    
              &::after{
                content: "";
              }
            }
            &-prev{
              left: calc(50% - 32px);
              bottom: 16px;
              top: auto;
              right: auto;
              transform: translateX(-50%);
              svg{
                transform: rotate(180deg);
              }
              &::after{
                content: "";
              }
            }
        
          }
        }
        &-thumbs{
          position: relative;
          margin-top: 12px;

          // height: 184px;
          @include popup(imgThumbs, height);

          .swiper-slide{
            position: relative;
    
            &::after{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #FFF;
              opacity: .5;
              transition: .16s all ease;
            }
            &.active{
              &::after{
                opacity: 0;
              }
            }
          }

          .swiper-button{
            &-next,
            &-prev{
              width: 43px;
              height: 100%;
              @media (max-width: map-get($media, tl)){
                $wh: 36px;
                width: $wh;
                // height: $wh;
              }
        
              @media (max-width: map-get($media, mx)){
                $wh: 30px;
                width: $wh;
                // height: $wh;
              }
    
              // border-radius: 1000px;
              background-color: map-get($color, buttonSwiper-2);
            
              svg{
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 12px;
                @include rp(tl){
                  width: 8px;
                }
              }
            }
    
            &-next{
              left: auto;
              bottom: auto;
              right: 0;
              top: 0;
    
              transform: none;
              margin: 0;
              &::after{
                content: "";
              }
            }
            &-prev{
              left: 0;
              top: 0;
              right: auto;
              bottom: auto;
              transform: none;
              margin: 0;


              svg{
                transform: rotate(180deg);
              }
              &::after{
                content: "";
              }
            }
        
          }
        }
        &-slide{
          height: 100%;
        }
      }

      // video library
      &__video{
        width: 100%;
        height: 100%;
      }

      // travel introduction
      &__travel{
        width: 100%;
        // height: 100%;
        align-self: stretch;

        .travel{
          width: 100%;
          height: 100%;
          padding: 48px;
          @include rp(ps){
            padding: 32px;
          }
          @include rp(tx){
            padding: 24px;
          }
          @include rp(tx){
            padding: 24px;
          }
          @include rp(ts){
            padding: 16px;
          }
          @include rp(mx){
            padding: 8px;
          }
          @include rp(mm){
            padding: 0px;
          }

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @include rp(ts){
            justify-content: flex-start;
          }
          flex-wrap: wrap;
          
          @include rp(ts){
            max-height: 100%;
            overflow: auto;
            
            &::-webkit-scrollbar {
              width: 13px;
    
              @include rp(tx){
                width: 8px;
              }
              @include rp(mx){
                width: 6px;
              }
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: map-get($color, track); 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: map-get($color, thumb);
              border-radius: 8px;
              @include rp(tx){
                border-radius: 3px;
              }
              @include rp(mx){
                border-radius: 2px;
              }
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: map-get($color, thumbHover); 
            }
          }

          &-img{
            width: 48%;
            @include rp(ts){
              width: 100%;
            }

            position: sticky;
            top: 0;
            align-self: stretch;

            @include rp(tl){
              position: initial;
              top: auto;
              align-self: initial;
            }

            img{
              height: auto;
              @include rp(ts){
                height: inherit;
              }
            }
          }
          &-content{
            width: 48%;
            height: 100%;
            overflow: auto;

            @include rp(ts){
              width: 100%;
              height: auto;
              overflow: visible;
            }

            margin-top: 0;
            @include rp(ts){
              margin-top: 36px;
            }
            @include rp(mm){
              margin-top: 24px;
            }

            &::-webkit-scrollbar {
              width: 13px;
    
              @include rp(tx){
                width: 8px;
              }
              @include rp(mx){
                width: 6px;
              }
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: map-get($color, track); 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: map-get($color, thumb);
              border-radius: 8px;
              @include rp(tx){
                border-radius: 3px;
              }
              @include rp(mx){
                border-radius: 2px;
              }
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: map-get($color, thumbHover); 
            }

            &>*:not(:first-child){
              margin-top: 45px;

              @include rp(tx){
                margin-top: 36px;
              }
              @include rp(mx){
                margin-top: 24px;
              }
            }

            &__item{
              
              &-title{
                display: flex;
                align-items: center;

                padding: 4px 8px 4px 16px;

                @include rp(ts){
                  padding: 4px 12px 4px 12px;
                }
                background-color: map-get($color, intro-bg-2);

                &>*:first-child{
                  flex: 1;
                  @include bold;
                  @include title(s26);
                  color: map-get($color, main);
                }

                img{
                  width: 16px;
                  height: 16px;
                  object-fit: contain;
                }
              }
              &-desc{
                padding: 0px 8px 0px 16px;
                @include rp(ts){
                  padding: 0 12px 0 12px;
                }
                margin-top: 24px;

                @include rp(tx){
                  margin-top: 16px;
                }
                @include rp(mx){
                  margin-top: 12px;
                }
                display: none;
                &>*{
                  height: 0;
                  transition: .3s all ease-in-out;
                }
              }

              &.active{
                .travel-content__item-desc{
                  display: block;
                  &>*{
                    text-align: justify;
                    transform-origin: top;
                    height: auto;
                    animation: goHeight .16s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
      // job introduction
      &__job{
        width: 100%;
        height: 100%;

        .job{
          width: 100%;
          height: 100%;

          display: flex;
          flex-direction: column;

          @include rp(ts){
            overflow: auto;

            &::-webkit-scrollbar {
              width: 13px;
    
              @include rp(tx){
                width: 8px;
              }
              @include rp(mx){
                width: 6px;
              }
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: map-get($color, track); 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: map-get($color, thumb);
              border-radius: 8px;
              @include rp(tx){
                border-radius: 3px;
              }
              @include rp(mx){
                border-radius: 2px;
              }
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: map-get($color, thumbHover); 
            }
          }

          &__title{
            margin-bottom: 40px;
            @include rp(ps){
              margin-bottom: 32px;
            }
            &>*{
              color: map-get($color, main);
              @include bold;
              @include family--primary;
              @include title(s41);
              text-align: center;
            }
          }

          &-wrapper{
            flex: 1;
            display: flex;
            overflow: hidden;

            @include rp(ts){
              overflow: initial;
            }

            @include rp(ts){
              flex-direction: column;
            }
          }

          &-options{
            max-width: 30%;
            padding-right: 10%;
            padding-top: 36px;
            
            @include rp(ts){
              max-width: 100%;
              padding-right: 0;
              
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;

              padding-top: 0;

            }
            
            padding-bottom: 40px;
            @include rp(ts){
              padding-bottom: 36px;

              margin-top: -8px;
              margin-left: -8px;
              margin-bottom: -8px;
            }

            &>*{
              cursor: pointer;
              min-width: 120px;
              &:hover{
                &>*{
                  @include bold;
                  color: map-get($color, main);
                }
              }

              &:not(:first-child){
                margin-top: 12px;

                @include rp(ts){
                  margin-top: 0px;
                }
              }

              &.active{
                &>*{
                  @include bold;
                  color: map-get($color, main);
                }
              }

              &>*{
                @include title(s26);
                @include uppercase;
                @include regular;

                white-space: nowrap;

                @include rp(ts){
                  padding-top: 8px;
                  padding-left: 8px;
                  padding-right: 8px;
                }
              }
            }

          }
          &-content{
            min-width: 70%;
            flex: 1;
            
            &__item{
              width: 100%;
              height: 100%;
              padding: 36px 12px 40px 40px;
              @include rp(ts){
                padding: 24px 12px 24px 24px;
              }
              display: none;

              &.active{
                display: block;
              }

              overflow: auto;
  
              &::-webkit-scrollbar {
                width: 13px;
      
                @include rp(tx){
                  width: 8px;
                }
                @include rp(mx){
                  width: 6px;
                }
              }
              
              /* Track */
              &::-webkit-scrollbar-track {
                background: map-get($color, track); 
              }
              
              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: map-get($color, thumb);
                border-radius: 8px;
                @include rp(tx){
                  border-radius: 3px;
                }
                @include rp(mx){
                  border-radius: 2px;
                }
              }
              
              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: map-get($color, thumbHover); 
              }
  
              @include rp(ts){
                overflow: initial;
                &::-webkit-scrollbar {
                  width: 0;
                  display: none;
                }
              }
  
              background-color: white;
              box-shadow: 0 3px 6px rgba(0,0,0,.16);
  
              h1, h2, h3, h4, h5, h6{
                color: map-get($color, main);
                @include bold;
              }
  
              h4{
                @include title(s26);
  
                &:first-child{
                  position: relative;
                  padding-bottom: 12px;
  
                  &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 62px;
                    height: 5px;
                    background-color: map-get($color, underline);
                  }
                }
              }
  
              p{
                line-height: 1.6;
                margin-top: 24px;
              }
            }
          }

        }
      }

      // wedding
      &__wedding{
        width: 100%;
        color: map-get($color, base);

        .wedding-cost{
          padding: 60px 134px 0 134px;
          @include rp(pm){
            padding: 60px 60px 0 60px;
          }

          @include rp(ts){
            padding: 32px 8px 0 8px;
          }

          &__title{
            &>*{
              color: map-get($color, main);
              text-align: center;
              padding-bottom: 16px;

              @include semi-bold;
              @include title(s41);

              position: relative;
              &::after{
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                
                width: 62px;
                height: 5px;
                background-color: map-get($color, underline);
              }
            }
          }
          &__table{
            margin-top: 72px;

            display: flex;
            justify-content: space-between;
            flex-direction: column;

            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 60px;

            &::-webkit-scrollbar {
              width: 13px;
              height: 13px;
              
              @include rp(tx){
                width: 8px;
                height: 8px;
              }
              @include rp(mx){
                width: 6px;
                height: 6px;
              }
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: map-get($color, track); 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: map-get($color, thumb);
              border-radius: 8px;
              @include rp(tx){
                border-radius: 3px;
              }
              @include rp(mx){
                border-radius: 2px;
              }
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: map-get($color, thumbHover); 
            }

            @include rp(ts){
              padding-bottom: 32px;
            }
          }
          &__row{
            display: flex;
            flex-direction: row;
            &:first-child, &.heading{
              &>*{
                color: map-get($color, main);
                @include uppercase;
                @include bold;
                @include title(s26);
              }
              margin-bottom: 28px;
            }
            &:nth-of-type(n + 3){
              margin-top: 18px;
            }

            &>*{

              &:nth-child(1){
                width: 80px;
                @include rp(tx){
                  width: 56px;
                }
                @include rp(ml){
                  width: 42px;
                }
              }
              &:nth-child(2){
                flex: 1;
                min-width: 254px;
              }
              &:nth-child(3){
                width: 15%;
                min-width: 120px;
              }
              &:nth-child(4){
                width: 25%;
                @include rp(tx){
                  width: 20%;
                  min-width: 180px;

                }
              }


              &:not(:first-child){
                margin-left: 12px;
              }
            }
          }
        }
        .wedding-select{
          padding-top: 60px;

          @include rp(ts){
            padding-top: 32px;
          }

          margin-left: 134px;
          margin-right: 134px;

          @include rp(pm){
            margin-left: 60px;
            margin-right: 60px;
          }
          @include rp(ts){
            margin-left: 8px;
            margin-right: 8px;
          }

          position: relative;
          &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid map-get($color, base);
            opacity: .3;
          }

          &__title{
            &>*{
              color: map-get($color, main);
              @include bold;
              @include title(s26);
              text-align: center;
              @include uppercase;
            }
          }
          &__list{
            margin-top: 32px;

            padding-left: 24px;
            @include rp(tx){
              margin-top: 24px;
              padding-left: 20px;
            }
            @include rp(mx){
              margin-top: 16px;
              padding-left: 18px;
            }
          }

          &__table{
            margin-top: 72px;

            display: flex;
            justify-content: space-between;
            flex-direction: column;

            padding-left: 134px;
            padding-right: 134px;
            @include rp(pm){
              padding-left: 0;
              padding-right: 0;
            }

            overflow-x: auto;
            overflow-y: hidden;

            &::-webkit-scrollbar {
              width: 13px;
              height: 13px;
              
              @include rp(tx){
                width: 8px;
                height: 8px;
              }
              @include rp(mx){
                width: 6px;
                height: 6px;
              }
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: map-get($color, track); 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: map-get($color, thumb);
              border-radius: 8px;
              @include rp(tx){
                border-radius: 3px;
              }
              @include rp(mx){
                border-radius: 2px;
              }
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: map-get($color, thumbHover); 
            }

            padding-bottom: 60px;

            @include rp(ts){
              padding-bottom: 32px;
            }
          }
          &__row{
            display: flex;
            flex-direction: row;
            &:first-child, &.heading{
              &>*{
                color: map-get($color, main);
                @include uppercase;
                @include bold;
                @include title(s26);
              }
              padding-bottom: 14px;
              margin-bottom: 14px;

              position: relative;
              &::after{
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 1px;
                border-bottom: 1px solid map-get($color, base);
                opacity: .3;
              }
            }

            &:nth-of-type(n + 3){
              margin-top: 18px;
            }

            &>*{
              &:nth-child(1){
                flex: 1;
                min-width: 254px;
              }
              &:nth-child(2){
                width: 15%;
                min-width: 120px;
              }
              &:nth-child(3){
                width: 25%;
                @include rp(tx){
                  width: 20%;
                  min-width: 180px;
                }
              }

              &:not(:first-child){
                margin-left: 12px;
              }
            }
          }
        }
      }

      &__hall{
        display: flex;
        justify-content: center;
        flex-direction: row;
        z-index: 1;

        @include rp(tl){
          flex-direction: column;
          justify-content: flex-start;
        }

        overflow: auto;

        .hall-left{
          position: sticky;
          top: 0;

          @include rp(tl){
            position: initial;
            top: auto;
          }

          display: grid;
          grid-template-columns: 1fr 1fr;

          row-gap: 12px;
          column-gap: 30px;

          @include rp(mx){
            column-gap: 12px;
          }

          &>*{
            &:first-child{
              grid-row: 1 / span 3;
            }
          }
        }

        .hall-right{
          padding-left: 5%;
          @include rp(tl){
            padding-left: 0%;
          }

          &__item{
            h1, h2, h3, h4, h5, h6{
              color: map-get($color, main);
              @include bold;
            }
            // h1{
            //   @include title(s41);
            // }
            h2{
              @include title(s26);
            }
  
            p{
              @include regular;
              line-height: 1.6;
              @include size(s18);
              color: map-get($color, base);
            }
  
            ul{
              // padding-left: 28px;
              @include popup(contentUlPopup, padding-left);
  
              li{
                &::marker{
                  @include size(s21);
                  color: map-get($color, base);
                }
              }
              li + li{
                // margin-top: 12px;
                @include popup(liPopup, margin-top);
              }
            }
  
            &:first-child{
              // margin-top: 24px;
              @include popup(itemPopup, margin-top);
              h1:first-child,
              h2:first-child,
              h3:first-child,
              h4:first-child,
              h5:first-child,
              h6:first-child{
                @include title(s41);
                @include family--primary;
                position: relative;
                padding-bottom: 8px;
                &::after{
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 0;
                  width: 100%;
                  height: 4px;
                  background-color: map-get($color, main);
                }
              }
            }
            &:not(:first-child){
              position: relative;
              // margin-top: 24px;
              // padding-top: 24px;
              @include popup(itemPopup, margin-top);
              @include popup(itemPopup, padding-top);

              h3{
                @include uppercase;
              }
  
              &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                border-bottom: 1px solid map-get($color, base);
              }
            }
            &>*:not(:first-child){
              // margin-top: 24px;
              @include popup(itemPopup, margin-top);
            }
          }
        }
      }
    }
  }
}

.image-popup{
  .popup-wrapper--bg{
    min-height: auto !important;
  }
}

.video-popup{
  .popup-wrapper--bg{
    @media (min-height: 800px) {
      height: 70%;
    }
    height: 60%;
  }
}
.travel-popup{
  .popup-wrapper--bg{
    min-height: auto;

    @include rp(tl){
      overflow-y: initial !important;
      flex-direction: initial !important;
      justify-content: flex-start !important;
    }
  }
}

.job-popup{
  .popup-wrapper--bg{
    background-color: map-get($color, intro-bg-2);

    @media (min-height: 800px) {
      height: 70%;
    }
    height: 60%;

    padding: 40px 36px 40px 72px;
    @include rp(ps){
      padding: 32px 20px 32px 56px;
    }
    @include rp(ts){
      padding: 32px 18px 32px 32px;
    }
  }
}


.wedding-popup{
  .popup-wrapper--bg{
    overflow: auto;

    &::-webkit-scrollbar {
      width: 13px;
      
      @include rp(tx){
        width: 8px;
      }
      @include rp(mx){
        width: 6px;
      }
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: map-get($color, track); 
    }
     
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: map-get($color, thumb);
      border-radius: 8px;
      @include rp(tx){
        border-radius: 3px;
      }
      @include rp(mx){
        border-radius: 2px;
      }
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: map-get($color, thumbHover); 
    }
  }
}

.total-popup{
  .popup-wrapper--bg{
    .flex-center{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
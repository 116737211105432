.endow-1{
  .title-primary {
    width: 100%;
    display: flex;
    justify-content: center;

    @include space(s48, margin-top);
    @include space(s48, margin-bottom);
  }

  .box-pagination{
    @include space(s60, margin-top);
  }

  .endow__slide{

    .slide-item{
      &__img{
        @include blockEndow(imgEndow1, height);
      }

      &__title{
        @include endowSlide(s20, padding-top);

        &>*{
          @include p-line-clamp(1);
          @include family--second;

          @include uppercase;
          @include title(s26);
        }
      }
      &__bottom{
        width: 100%;
        border-top: 1px solid map-get($color, base);

        @include endowSlide(s20, padding-top);
        @include endowSlide(s20, margin-top);

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &--desc{
          color: map-get($color, base);
        }
      }
    }
  }
}
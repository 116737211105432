.index4 {
    .index-uilities {
        .title-primary{
            width: 100%;
            display: flex;
            justify-content: center;
    
            @include space(s90, margin-top);
            @include space(s48, margin-bottom);
        }
        &__wrapper {
            position: relative;
            @include blockIndex(imgIndex4,height);
        }
        &__layout {
            display: flex;
            height: 100%;

            &--img {
                width: 70%;
                height: 100%;

                @include rp(tx){
                    width: 100%;
                }
                
                position: relative; 

                display: none;
                clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
                &.show{
                    display: block;
                    animation: clipPathToLeft .7s ease forwards;
                }
            }
                
            &--color{
                width: 30%;
                position: relative;

                @include blockIndex(imgIndex4, height);
                // background: map-get($color, main);
                background-color: #003F7A;

                @include rp(tx){
                    display: none;
                }
            }
        }
        &__content {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .container {
            height: 100%;
        }
        &__box {
            position: absolute;
            top: 50%;
            right: 0;
            width: 744px;
            
            @include rp(tx){
                width: 640px;
            }
            @include rp(ts){
                width: 90%;
            }
            @include rp(mx){
                width: 98%;
            }
            transform: translateY(-50%);
            display: flex;

            @include blockIndex(boxIndex4,height);

            @include rp(pl){
                right: 4%;
            }
            @include rp(tx){
                // width: 100%;
                right: initial;
                left: 50%;
                transform: translate(-50% , -50%);
            }
           

        }
        &__left{
            // @include blockIndex(boxIndex4,height);
            align-self: stretch;
            
            background: map-get($color , mainBg );
            box-sizing: border-box;
            position: relative;

            z-index: 1;
            &::after {
                z-index: -1;
                position: absolute;
                content: "";
                top: 20px;
                left: 20px;
                right: 20px;
                bottom: 20px;
                border: 1px solid map-get($color, main );
                @include rp(mx){
                    top: 14px;
                    left: 14px;
                    right: 14px;
                    bottom: 14px;
                }
            }
            @include rp(tx){
                width: 80%
            }
        }
        &__boxleft{     
            height: 100%;
            @include spaceIndex4(s24,padding);
        }
        .content{
            @include spaceIndex4(s104,padding-top);
            @include spaceIndex4(s104,padding-bottom);
            @include spaceIndex4(sLeftRightContentBox,padding-right);
            @include spaceIndex4(sLeftRightContentBox,padding-left);
            
            
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;

            &__title{
                @include spaceIndex4(s70, margin-bottom);
                position: relative;
                &::after {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    content: "";
                    height: 6px;
                    background: map-get($color, underline);
                    width: 25%;
                }
                &--size {
                    // @include size(s58);
                    @include title(s51);

                    color: map-get($color, main);
                    font-weight: bold;
                    @include family--primary;
                }
            }
            &__desc {
                @include spaceIndex4(s40, margin-bottom);
                &--size {
                    @include size(s18);
                    line-height: 1.8;
                    color: map-get($color, base);
                    @include p-line-clamp(6);

                }
            }
            &__more {
                svg {
                    margin-left: 8px;
                }
            }
        }
        &__right{
            
            // width: 120px;
            // @include rp(tm){
            //     width: 108px;
            // }
            // @include rp(ts){
            //     width: 98px;
            // }
            // @include rp(mx){
            //     width: 88px;
            // }
            // @include rp(ml){
            //     width: 80px;
            // }
            // @include rp(ms){
            //     width: 64px;
            // }
           
            @include spaceIndex4(s12,margin-left);
            height: 100%;

            &--list{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                &>*{
                    height: 19.5%;
                }
            }
            &--items{
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;

                // @include blockIndex(boxRighrIndex4, height);

                background: map-get($color, main);

                list-style-type: none;
                text-align: center;
                // @include spaceIndex4(s2, margin, 0);
              

                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

                @include rp(mx){
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                }
            
                &.active {
                    background: map-get($color, mainOverlay);
                    width: 140px;
                    @include rp(tm){
                        width: 126px;
                    }
                    @include rp(ts){
                        width: 118px;
                    }
                    @include rp(mx){
                        width: 104px;
                    }
                    @include rp(ml){
                        width: 92px;
                    }
                    @include rp(ms){
                        width: 72px;
                    }
                    position: relative;
                    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
                    &::after {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 100%;
                        height: 100%;
                        width: 10%;
                        background: map-get($color, underline);
                    }
                }
            }
            &--img{
                @include rp(mx){
                    height: 70%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                svg{
                    // width: 72px;
                    height: 48px;
                    @include rp(tx){
                        // width: 64px;
                        height: 44px;
                    }
                    @include rp(mx){
                        // width: 64px;
                        height: 70%;
                    }
                }
            }
            &--desc{
                // @include size(s11);
                font-size: 11px;

                color: map-get($color,mainBg );
                @include spaceIndex4(s16, margin-top);
                @include rp(mx){
                    display: none;
                }


            }
        }

    }
}

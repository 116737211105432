
$spaceCuisine1:(
    s54: (
        default: 54px,
        pl: 48px,
        pm: 36px,
        tm: 32px,
        ml: 24px,
    ),
    s70:(
        default: 70px,
        pl: 48px,
        pm: 24px,
        tm: 20px,
    ),
    sRight: (
        default: 70px,
        pl: 60px,
        ps: 50px,
        tm: 0px,
    ),
);
$spaceCuisine2:(
  s16:(
    default: 16px,
    pm: 14px,
  ),
  s70:(
    default: 70px,
    pm: 64px,
    tx: 56px,
    tm: 48px,
    ts: 44px,
    mx: 40px,
    
    
   
),
  s170:(
    default: 170px,
    tx: 88px,
    ts: 72px,
    mx: 64px,
    ml: 56px,
  ),
    sLeft: (
        default: 70px,
        pl: 50px,
        pm: 40px,
        tx: 36px,
        tl: 34px,
        tm: 0,
        
        
    ),
);
$spaceCuisine3:(
  s16:(
    default: 16px,
    pm: 14px,
  ),
  s70:(
    default: 70px,
    pm: 64px,
    tx: 56px,
    tm: 48px,
    ts: 44px,
    mx: 40px,
    
    
   
),
  s170:(
    default: 170px,
    tx: 88px,
    ts: 72px,
    mx: 64px,
    ml: 56px,
  ),
    sLeft: (
        default: 70px,
        pl: 50px,
        pm: 40px,
        tx: 36px,
        tl: 34px,
        tm: 0,
        
        
    ),
);


$blockCuisine: (
    sectionCuisine1:(
        default: 500px,
        pl: 460px,
        pm: 405px,
        ps: 400px,
        tm: 380px,
        ml: 350px,
        mm: 270px,
        

    ),
    imgCuisine1: (
        default: 380px,
        pl: 360px,
        pm: 330px,
        tm: 300px,
        mx: 290px,
        ml: 260px,
        mm: 220px,
        sx: 200px,
        
    ),
    wImgCuisine1: (
      default: 530px,
      pl: 470px,
      pm: 410px,
      mx: 380px,
      ml: 80%,
      // mm: 280px,
      // ms: 330px,
      // sx: 100%

    ),
    imgCuisine1-2: (
        default: 225px,
        pl: 205px,
        pm: 180px,
        tm: 170px,
        mx: 150px,
        mm: 140px,
        
    ),
    wImgCuisine1-2: (
      default: 400px,
      pl: 355px,
      pm: 295px,
      mx: 60%,
      // ml: 250px,
      // mm: 250x,
      
    ),
    listCuisine1: (
      default: 96px,
      pl: 88px,
      pm: 80px,
      tm: 64px,
      mx: 56px,
      ml: 48px,
    ),
    ImgIcons:(
      default: 72px,
      pl: 64px,
      tx: 56px,
      mx: 48px,
      ml: 36px,
    ),
    sectionCuisine2:(
      default: 580px,
      pl: 510px,
      pm: 470px,
      tx: 440px,
    ),
    wimgCuisine2:(
        default: 100%,
        // pl: 740px,
        // pm: 630px,
        // tx: 600px,
        // tl: 550px,
        tm: 100%,
    ),
    himgCuisine2:(
        default: 580px,
        pl: 510px,
        pm: 470px,
        tx: 440px,
        tl: 400px,
        ts: 360px,
        mx: 330px,
        ml: 280px,
        mm: 240px,
        ms: 200px,
        sx: 160px,
    ),
    wimgCuisine3:(
        default: 100%,
        // pl: 740px,
        // pm: 630px,
        // tx: 600px,
        // tl: 550px,
        tm: 100%,
    ),
    himgCuisine3:(
        default: 580px,
        pl: 510px,
        pm: 470px,
        tx: 400px,
        tl: 400px,
        ts: 360px,
        mx: 330px,
        ml: 280px,
        mm: 240px,
        ms: 200px,
        sx: 160px,
    ),
);


@mixin blockCuisine($key, $name, $side: '-1'){
    @each $idx, $val in map-get($blockCuisine, #{$key}){
      @if $side == '-1'{
        @if $idx == default{
          #{$name}: $val;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val;
          }
        }
      }
      @else{
        @if $idx == default{
          #{$name}: $val $side;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val $side;
          }
        }
      }
    }
  }
@mixin spaceCuisine1($key, $name, $side: '-1'){
    @each $idx, $val in map-get($spaceCuisine1, #{$key}){
      @if $side == '-1'{
        @if $idx == default{
          #{$name}: $val;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val;
          }
        }
      }
      @else{
        @if $idx == default{
          #{$name}: $val $side;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val $side;
          }
        }
      }
    }
  }
@mixin spaceCuisine2($key, $name, $side: '-1'){
    @each $idx, $val in map-get($spaceCuisine2, #{$key}){
      @if $side == '-1'{
        @if $idx == default{
          #{$name}: $val;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val;
          }
        }
      }
      @else{
        @if $idx == default{
          #{$name}: $val $side;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val $side;
          }
        }
      }
    }
  }
@mixin spaceCuisine3($key, $name, $side: '-1'){
    @each $idx, $val in map-get($spaceCuisine3, #{$key}){
      @if $side == '-1'{
        @if $idx == default{
          #{$name}: $val;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val;
          }
        }
      }
      @else{
        @if $idx == default{
          #{$name}: $val $side;
        }
        @else{
          @media (max-width: map-get($media, $idx)){
            #{$name}: $val $side;
          }
        }
      }
    }
  }

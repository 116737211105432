#index5{
    @include spaceIndex5(s190, margin-top);
    .map__wrapper{
        position: relative;
        overflow: hidden;
        &--map{
            width: 100%;
            @include blockIndex(mapIndex, height);
            iframe{
                width: 100%;
                height: 100%;
            }
        }
        &--btnclose{
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            @include blockIndex(mapBtnIndex, width);
            height: 100%;
            background-color: map-get($color, base );
            display: flex;
            justify-content: center;
            align-items: center;
            .active{
                transform: rotate(180deg);
            }
            svg{
                transition: 0.5s ease-in-out;
            }
            path{
                stroke:  map-get($color, mainBg );;
            }
        }
        &--infor.active{
            @include blockIndex(mapBtnIndex, right);
        }
        &--infor{
            transition: 0.5s ease-in-out;
            position: absolute;
            top: 0;
            right: -636px;
            @include blockIndex(mapInforIndex, width);
            // max-width: 300px;
            height: 100%;
            background-color: map-get($color, boxVideoOverlay );
            @include spaceIndex5(s15, padding);
            .pic__infor{
                width: 100%;
                height: 100%;
                .image__map{
                    height: 55%;
                    @include rp(tx){
                        height: 45%;
                    }
                   
                }
                .introduce-text{
                    // overflow-y: scroll;
                    @include spaceIndex5(s40,padding);
                    display: flex;
                    flex-direction: column;
                    
                    align-content: center;
                    height: 45%;
                    @include rp(tx){
                        height: 55%;
                    }
                    
                    background-color: map-get($color, mainBg );
                    &--title{
                        @include title(s41);
                        @include family--primary;
                        font-weight: bold;
                        color: map-get($color, main );
                    }
                    &--infor{
                        @include spaceIndex5(s40,margin-top );
                        
                        &>*{
                            line-height: 1.4;
                        }
                        .infor--item{
                            &:not(:last-child){
                                @include spaceIndex5(s20,margin-bottom);
                            }
                            
                            .item{
                                display: flex;
                                img{
                                    margin-right: 15px;
                                    width: 19px;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}
.service-1{
  .tour-title{
    text-align: center;
    width: 100%;

    @include space(s90, margin-top);
    @include space(s90, margin-bottom);
  }

  .tour-content{
    &--container{
      @extend .margin-auto;
      
      &>*{
        @include blockService(tourContentService, height); 
        padding-left: 0;
        padding-right: 0;
      }

      @include rp(mx){
        flex-direction: column;
      }
    }


    &-card{
      position: relative;
      transform-style: preserve-3d;
      
      &:hover{
        .tour-content__img{
          transform: perspective(1000px) rotateY(-180deg);
          z-index: 1;
        }
        .tour-content__box{
          transform: perspective(1000px) rotateY(0deg);
          z-index: 2;
        }
      }

      &.tour-content-card--defalt{
        
        .tour-content__img{
          transform: none;
        }
        .tour-content__box{
          transform: none;
        }
        &:hover{
          .tour-content__box{
            transform: none;
          }
          .tour-content__img{
            transform: none;
          }
        }
      }

      @include rp(mx){
        flex: initial;
        max-width: 100%;
      }
    }

    &__img, &__box{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: map-get($color, main );

      transition: .7s transform ease-out;
    }

    &__img{
      transform: perspective(1000px) rotateY(0deg);
      z-index: 2;
    }

    &__box{
      border: 1px solid map-get($color, main);
      transform: perspective(1000px) rotateY(180deg);
      z-index: 1;
    }

    .box-item{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      @include serviceBox(s52, padding);
      
      @include rp(mx){
        justify-content: center;
      }

      &-icons{
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        
        @include serviceBox(s90, padding-left);
        @include serviceBox(s90, padding-right);

        flex-wrap: wrap;

        &>*{
          @include blockService(tourIconService, width);
          @include blockService(tourIconService, height);

          margin: 1.2rem;

          img{
            object-fit: contain;
          }
        }
      }

      &-content{
        height: 50%;
        
        @include rp(mx){
          justify-content: center;
        }

        color: map-get($color, mainBg);
        text-align: center;

        line-height: 1.4;
        &>*{
          @include serviceBox(s28, margin-top);
        }

        &__title{
          &>*{
            // @include size(s21);
            @include title(s21);

            @include bold;

            display: inline-block;
            @include p-line-clamp(1);
          }

          position: relative;
          &::after{
            content: "";
            position: absolute;
            bottom: -1.2rem;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 50px;
            height: 3px;
            background-color: map-get($color, underline);
          }
        }

        &__desc{
          @include serviceBox(s24, padding-left);
          @include serviceBox(s24, padding-right);

          min-height: 75px;
          line-height: 1.4;

          &>*{
            // @include size(s18);
            @include title(s18);

            @media (max-width: map-get($media, tl)) {
              @include p-line-clamp(2);
            }
          }

          @media (max-width: map-get($media, ts)) {
            &>p{
              display: none;
            }
            min-height: auto;
          }
          @media (max-width: map-get($media, mx)) {
            span{
              display: block;
            }
          }
        }

        &__time{
          span{
            @include bold;
          }
        }

        &__more{
          @media (max-width: map-get($media, ts)) {
            // margin-top: 24px;
          }
          &>*{
            color: map-get($color, mainBg);
            @include size(s12);
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: center;

            svg{
              margin-left: 1rem;
            }

            &:hover{
              svg{
                path{
                  $colorHover: map-get($color, underline);

                  stroke: $colorHover;
                  fill: $colorHover;
                }
              }
            }
          }
        }
      }
    }
  }
}
.booking {
  // margin-top: 120px;
  @include spaceBooking(s120, margin-top);

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-step {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: auto;
    margin: auto;

    margin-top: -18px;
    margin-left: -18px;
    margin-right: -18px;

    // padding-bottom: 50px;
    @include spaceBooking(s50, padding-bottom);

    border-bottom: 1px solid map-get($color, main);
    & > * {
      margin-top: 18px;
      margin-left: 18px;
      margin-right: 18px;
    }

    &__item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;

      & > * {
        &:not(:first-child) {
          margin-left: 12px;
        }
      }

      user-select: none;
      cursor: default;
      pointer-events: none;

      &-number {
        width: 48px;
        height: 48px;

        @include rp(ts) {
          width: 36px;
          height: 36px;
        }

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 500px;
        background-color: map-get($color, iconMainBgActive);
        color: map-get($color, baseBold);
        @include title(s26);
        @include bold;
      }
      &-span {
        @include title(s26);
        @include regular;
        color: map-get($color, baseBold);
        white-space: nowrap;
      }

      &.active {
        .booking-step__item-number {
          background-color: map-get($color, main);
          color: white;
        }
        .booking-step__item-span {
          color: map-get($color, main);
          @include bold;
        }
      }
    }
  }

  &-form {
    // margin-top: 90px;
    // margin-bottom: 90px;
    @include spaceBooking(s90, margin-top);
    @include spaceBooking(s90, margin-bottom);

    @include rp(mx) {
      width: 100%;
    }
    // booking info
    .form-step {
      max-width: 812px;
      margin: auto;

      &__item {
        &:not(:first-child) {
          margin-top: 42px;
          @include rp(ts) {
            margin-top: 36px;
          }
          @include rp(ml) {
            margin-top: 24px;
          }
        }
        & > *:not(:first-child) {
          margin-top: 24px;
          @include rp(ts) {
            margin-top: 18px;
          }
          @include rp(ml) {
            margin-top: 12px;
          }
        }
        &-input {
          position: relative;
          max-width: 100%;
          height: 56px;

          input {
            width: 100%;
            height: 100%;

            padding-left: 24px;

            border: 2px solid map-get($color, baseBold);
            &:focus {
              border: 2px solid map-get($color, main);
            }
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-img {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          object-fit: contain;
          margin: 0 !important;
          width: auto;
          height: 36px;
          padding-bottom: 4px;
          pointer-events: none;

          img {
            object-fit: contain;
          }
        }

        &-submit {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;

          button,
          input[type="submit"] {
            outline: none;
            background: transparent;
            border: none;
            padding: 8px 16px;
            min-width: 120px;

            margin: auto;
            background-color: map-get($color, main);
            @include bold;
            color: white;
            @include uppercase;
            font-size: 1.7rem;
          }
        }
      }
    }

    // booking select
    .select {
      $pd1: 150px;
      @include rp(pl){
        $pd1: 90px;
      }
      @include rp(pm){
        $pd1: 48px;
      }
      @include rp(mx){
        $pd1: 36px;
      }
      @include rp(ml){
        $pd1: 24px;
      }
      @include rp(mm){
        $pd1: 16px;
      }

      &-info {
        &__title {
          text-align: center;
          & > * {
            @include title(s51);
            @include bold;
            color: map-get($color, main);
            @include family--primary;
          }
        }
        &-details {
          margin-top: 18px;
          display: flex;
          justify-content: center;
          .detail-left {
            background-color: white;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            position: relative;
            &--overlay {
              position: absolute;
              top: 10px;
              left: 10px;
              right: 10px;
              bottom: 10px;
              border: 1px solid map-get($color, main);
              background-color: transparent;
            }

            &__info {
              padding: 90px;
              @include rp(ts){
                padding: 64px;
              }

              @include rp(mx){
                padding: 48px;
              }
              @include rp(ml){
                padding: 36px;
              }

              display: flex;
              flex-direction: column;
              flex-wrap: wrap;

              &-item {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;

                img {
                  align-self: self-start;

                  width: 32px;
                  height: 32px;
                  object-fit: contain;
                }
                p {
                  margin-left: 32px;
                  @include rp(mx){
                    margin-left: 16px;
                  }
                  flex: 1;
                }

                &:not(:first-child) {
                  margin-top: 40px;
                }
              }
            }
          }

          .detail-right {
            padding-top: 40px;
            padding-left: 64px;

            & > * {
              margin-top: 36px;
            }

            &__title {
              & > * {
                @include regular;
                padding-bottom: 8px;
                border-bottom: 1px solid map-get($color, base);
              }
            }
            &-superior {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              &__left {
                display: flex;
                flex-direction: column;
                & > * {
                  &:not(:first-child) {
                    margin-top: 6px;
                  }
                }
                h4 {
                  @include bold;
                  @include title(s26);
                }
                &-icons {
                  & > * {
                    width: auto;
                    height: 20px;
                    object-fit: contain;

                    &:not(:first-child) {
                      margin-left: 8px;
                    }
                  }
                }
              }
              &__right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;

                &-option {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  flex-wrap: wrap;
                  align-items: center;
                  width: 100%;

                  & > * {
                    &:not(:first-child) {
                      margin-left: 24px;
                    }
                  }

                  .option {
                    position: relative;

                    padding: 10px 0;
                    border: 1px solid map-get($color, base);

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    min-width: 60px;
                    &-value {
                      padding-left: 12px;
                    }
                    &-icon {
                      position: absolute;
                      top: 50%;
                      right: 8px;
                      transform: translateY(-50%);
                    }

                    &-list {
                      position: absolute;
                      top: calc(100% + 2px);
                      left: 0;

                      background-color: white;
                      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

                      width: 100%;

                      max-height: 150px;
                      overflow-y: auto;

                      display: none;

                      /* width */
                      &::-webkit-scrollbar {
                        width: 3px;
                      }

                      /* Track */
                      &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                      }

                      /* Handle */
                      &::-webkit-scrollbar-thumb {
                        border-radius: 3px;
                        background: #888;
                      }

                      /* Handle on hover */
                      &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                      }

                      & > * {
                        padding: 6px 12px;
                        &:hover {
                          background-color: rgba(map-get($color, main), 0.7);
                        }
                        &.active {
                          background-color: map-get($color, main);
                        }
                      }

                      &.active {
                        display: block;
                      }
                    }
                  }
                }
                &-info {
                  align-self: flex-end;
                  & > * {
                    font-size: 10px;
                    color: map-get($color, error);
                  }
                }
              }
            }

            &-value {
              &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;

                .value__item {
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  &:first-child {
                    width: 148px;
                  }

                  &:nth-of-type(2n + 1) {
                    margin-left: 24px;
                  }

                  & > * {
                    &:not(:first-child) {
                      margin-left: 16px;
                    }
                  }

                  &-icons {
                    display: flex;
                    & > * {
                      width: 18px;
                      height: auto;
                      object-fit: contain;
                    }
                  }

                  &-date {
                    display: flex;
                    align-items: center;

                    & > * {
                      &:not(:first-child) {
                        margin-left: 24px;
                      }
                    }
                  }

                  &-span {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }

      $mt: 42px;
      @include rp(tm){
        $mt: 24px;
      }

      &-product {
        margin-top: $mt;

        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;

        $sp: 24px;
        @include rp(mx){
          $sp: 12px;
        }      
        $heightInput: 48px;

        $size: 2.2rem;

        &-toolbar {
          
          width: 100%;
          background-color: map-get($color, main);

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          @include rp(tx){
            flex-direction: column;
          }
          justify-content: center;

          padding: 32px $pd1;

          & > * {
            &:not(:first-child) {
              margin-left: $sp;
              @include rp(tx){
                margin-left: 0;
                margin-top: $sp;
              }
            }
          }

          .input {
            width: 45%;
            @include rp(tx){
              width: 100%;
            }
            &-date {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              @include rp(ml){
                flex-direction: column;
              }
              &>*{
                &:not(:first-child){
                  margin-left: $sp;
                  @include rp(ml){
                    margin-left: 0;
                    margin-top: $sp;
                  }
                }
              }

              &__group {
                position: relative;
                height: $heightInput;

                width: 100%;

                &-icon {
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);

                  height: 80%;
                }
              }
            }
          }

          label {
            color: map-get($color, subInput);
            font-size: $size;
          }

          input {
            border: none;
            outline: none;
            background: transparent;
            width: 100%;
            height: 100%;
      
            background-color: white;
            padding-left: $sp;
      
            font-size: $size;
          }
          
          .input-content {
            position: relative;
            height: $heightInput;
          }

          .option {
            // width: 55%;
            width: calc(55% - 12px);

            @include rp(tx){
              width: 100%;
            }
            display: flex;
            @include rp(ml){
              flex-direction: column;
            }

            & > * {
              flex: 2;
      
              &:not(:first-child) {
                margin-left: $sp;
      
                @include rp(ml){
                  margin-left: 0;
                  margin-top: $sp;
                }
              }

              &:last-child{
                flex: 1;
                @include rp(ml){
                  flex: 2;
                }
              }
            }
      
            &-item{
              position: relative;
            }
      
            .input-content {
              display: flex;
              justify-content: center;
              align-items: center;
      
              &__value {
                background-color: white;
                flex: 1;
                height: 100%;
      
                display: flex;
                align-items: center;
                padding-left: $sp;
      
                font-size: 2.2rem;
      
                cursor: pointer;
                user-select: none;

                &>*{
                  &:not(:first-child){
                    margin-left: 4px;
                  }
                }

                img{
                  height: auto;
                  width: auto;
                }
              }
              &__img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
      
                width: auto;
      
                pointer-events: none;
              }
            }
            .group-option {
              &-list {
                position: absolute;
                top: calc(100% + 4px);
                left: 0;
                width: 100%;
                background-color: white;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
      
                // margin: 0 12px;
      
                overflow: auto;
                z-index: 11111;
      
                max-height: 180px;
                overflow: auto;
      
                display: none;
      
                &.active {
                  display: block;
                }
              }
      
              &-item {
                padding: 12px $sp;
                cursor: pointer;
                display: flex;
                align-items: center;
                &>*:not(:first-child){
                  margin-left: 4px;
                }

                &.active {
                  color: white;
                  background-color: map-get($color, main);
                  span {
                    color: inherit;
                  }
                  pointer-events: none;
                }
      
                transition: 0.16s all ease-in;
                // &.active:hover{
                //   // color: white;
                //   // background-color: rgba(map-get($color, main),.7);
                //   // span{
                //   //   color: inherit;
                //   // }
                // }
                &:hover {
                  color: white;
                  background-color: rgba(map-get($color, main), 0.7);
                  span {
                    color: inherit;
                  }
                }

                img{
                  width: auto;
                  height: auto;
                  object-fit: contain;
                }
              }
            }
          }

          .form-footer__submit{
            margin-left: 0;
            width: 100%;
          }
        }

        &-list{
          margin-top: $mt;
          
          display: flex;
          flex-direction: row;

          @include rp(ts){
            flex-direction: column;
          }

          order: 1;
          
          flex: 1;
        }

        &-button{
          order: 2;
          position: sticky;
          top: calc(183px + #{$mt}); 
          height: 200px;
          
          @include rp(ps){
            top: calc(162px + #{$mt});
          }

          @include rp(tl){
            top: calc(138px + #{$mt});
          }

          @include rp(ts){
            position: static;
            top: 0;
            height: auto;
          }

          width: calc(150px - 16px);
          flex-shrink: 0;
          margin-top: $mt;

          @include rp(ts){
            display: flex;
            flex-direction: row-reverse;

            justify-content: space-between;
            align-items: center;

            width: 100%;

            .btn-link--booking{
              align-self: center;
              display: inline-block;
            }
          }

          &>*{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include rp(ts){
              width: auto;
            }
          }

          &__info{
            display: flex;
            flex-direction: column;

            .price{
              // @include title(s26);
              @include title(s18);
              text-align: center;
            }

            &>*{
              margin-top: 12px;
            }
          }
        }

        &-info{
          padding-right: 16px;

          @include rp(ts){
            padding-right: 0;
          }

          order: 1;
          flex: 1;

          &__item{
            &:not(:first-child){
              margin-top: 42px;
            }
            .room{
              padding: 36px $pd1;

              background-color: map-get($color, footer-bg);
              &-info{
                display: flex;
                flex-direction: row;
                @include rp(tm){
                  flex-direction: column;
                }
                // &>*{
                //   flex: 1;
                // }
                &__img{
                  width: 40%;
                  @include rp(pm){
                    width: 45%;
                  }
                  padding-right: 1%;
                  @include rp(tm){
                    width: 100%;
                    padding-right: 0;
                  }
                }
                &__content{
                  flex: 1;
                  padding-left: 1%;
                  @include rp(tm){
                    padding-left: 0;
                    padding-top: 12px;
                  }
                  &-item{
                    &:not(:first-child){ 
                      margin-top: 18px;
                      padding-top: 18px;
                      border-top: 1px solid rgba(map-get($color, base), .3);
                    }
                    &>*{
                      // padding: 8px 0;
                      &:not(:first-child){
                        margin-top: 18px;
                      }
                    }
        
                    .box-desc-icons{
                      &>*:not(:first-child){
                        padding-left: 0;
                        // margin-left: 40px;
                        @include spaceRest(descIconList, margin-left);
        
                        &::after{
                          display: none;
                        }
                      }
                    }
        
                    ul{
                      list-style-type: none;
                      display: flex;
                      flex-direction: row;
                      flex-wrap: wrap;
                      // justify-content: space-between;
        
                      li{
                        display: flex;
                        align-items: center;
        
                        img + img{
                          margin-left: 4px;
                        }
                      }
                    }
        
                    ul>li{
                      &:not(:first-child){
                        // margin-left: 8px;
                        // padding-left: 8px;
                        @include spaceRest(descLine, margin-left);
                        @include spaceRest(descLine, padding-left);
        
                        // border-left: 1px solid map-get($color, baseBold);
                      
                        position: relative;
        
                        &::after{
                          content: "";
                          position: absolute;
                          top: 0;
                          left: 0;
                          height: 100%;
                          width: 1px;
                          // background-color: map-get($color, baseBold);
                          border-left: 1px solid map-get($color, baseBold);
                        }
                      }
                    }
        
                    ul>li img{
                      width: initial;
                      height: initial;
                    } 
                  }
                  &-title{
                    &>*{
                      @include title(s41);
                      color: map-get($color, main);
                      @include bold;
                    }
                  }
                  &-desc{
                    margin-top: 28px;
                  }
                }
              }

              &-option{
                &-item{
                  display: flex;
                  flex-direction: row;
                  @include rp(mx){
                    flex-direction: column;
                  }
                  
                  padding-top: 36px;
                  margin-top: 48px;
                  @include rp(tx){
                    padding-top: 24px;
                    margin-top: 24px;
                  }

                  border-top: 1px solid rgba(map-get($color, base), .3);

                  &__left{
                    width: 40%;
                    @include rp(mx){
                      width: 100%;
                    }
                    &-title{
                      &>*{
                        @include bold;
                        @include title(s26);
                        color: map-get($color, baseBold);
                      }
                    }
                    &-list{
                      margin-top: 18px;

                      list-style-type: none;
                      li{
                        display: flex;
                        flex-direction: row;

                        .icons{
                          flex-shrink: 0;
                          width: 36px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding-right: 4px;
                          img{
                            width: auto;
                            height: auto;
                            object-fit: contain;
                          }
                        }
                        p{
                          flex: 1;
                        }
                      }

                      li + li{
                        margin-top: 12px;
                      }
                    }
                  }

                  &__right{
                    padding-left: 12px;
                    
                    @include rp(mx){
                      padding-left: 0;
                      padding-top: 12px;
                    }

                    flex: 1;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    &-item{
                      @include rp(mm){
                        &:last-child{
                          width: 100%;
                          margin-top: 12px;
                        }
                      }
                      .opt-icons{
                        list-style-type: none;

                        display: flex;
                        flex-direction: row;

                        li + li{
                          margin-left: 4px;
                        }
                      }
                      .price{
                        img{
                          width: auto;
                          height: auto;
                          object-fit: contain;

                          flex-shrink: 0;
                        }
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        &__percent{
                          display: flex;
                          justify-content: center;
                          align-self: center;

                          padding: 4px 6px;
                          border: 1px solid map-get($color, percent);
                        
                          font-size: 10px;
                          color: map-get($color, percent);

                          &>*:not(:first-child){
                            margin-left: 4px;
                          }
                        }

                        &__sale{
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: center;
                          &>*{
                            &:not(:first-child){
                              margin-left: 8px;
                            }
                          }
                          p{
                            color: map-get($color, baseBold);
                            text-decoration: line-through;
                          }
                        }

                        &__main{
                          display: flex;
                          align-items: center;
                          p{
                            color: map-get($color, main);
                            @include title(s26);
                            @include bold;
                          }
                        }

                        &__times{
                          margin-left: 4px;
                          p{
                            @include title(s18);
                            @include regular;
                            text-align: center;
                          }
                        }
                      }

                      .option{
                        &-desc{
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: center;
                          
                          &-p{
                            margin-right: 24px;
                          }
                          &-select{
                            position: relative;

                            .input{
                              display: flex;
                              flex-direction: row;

                              padding: 4px 6px;
                              border: 1px solid map-get($color, base);
                            
                              &>*:not(:first-child){
                                margin-left: 8px;
                              }
                            }

                            .group-option{
                              &-list{
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 100%;
                                background-color: white;
                                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
                              
                                // padding: 0 6px;
                  
                                overflow: auto;
                                z-index: 11111;
  
                                max-height: 120px;
                                overflow: auto;
  
                                display: none;
  
                                &.active{
                                  display: block;
                                }
                              }

                              &-item{
                                padding: 4px 6px;
                                cursor: pointer;
                                &.active{
                                  color: white;
                                  background-color: map-get($color, main);
                                  span{
                                    color: inherit;
                                  }
                                  pointer-events: none;
                                }
                
                                transition: .16s all ease-in;

                                &:hover{
                                  color: white;
                                  background-color: rgba(map-get($color, main),.7);
                                  span{
                                    color: inherit;
                                  }
                                }
                              }
                            }

                          }
                        }
                        &-info{
                          color: map-get($color, percent);
                          font-size: 10px;

                          text-align: right;
                          @include rp(mm){
                            text-align: center;
                          }
                          margin-top: 8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

$spaceBooking: (
  s120: (
    default: 120px,
    pm: 90px,
    ts: 72px,
    mx: 64px
  ),
  s90: (
    default: 90px,
    pm: 64px,
    ts: 56px,
    mx: 48px,

  ),
  s50: (
    default: 50px,
    pm: 48px,
    ts: 36px,
  
  ),
);

$blockBooking: (

);

@mixin blockBooking($key, $name, $side: '-1'){
  @each $idx, $val in map-get($blockBooking, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}


@mixin spaceBooking($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spaceBooking, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (max-width: map-get($media, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}
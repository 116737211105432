.index-restroom {
  // background: map-get($color, main );
  background: #003F7A;
  position: relative;

  .container{
    z-index: 1;
  }

  .path-restroom{
    height: 70%;
    top: auto;
    bottom: 0;
  }

  &__title {
    text-align: center;
    @include spaceIndex(s42, padding-top);
    @include spaceIndex(s42, padding-bottom);

    &--size{
    // @include size(s51);
    @include title(s51);
    @include family--primary;
    color: map-get($color, mainBg);
    font-weight: bold;
    }
  }
  &__slide {
    position: relative;
    $wh: 43px;
    .swiper-button-next,
    .swiper-button-prev{
      &::after{
        content: "";
      }
      background-color: map-get($color, buttonSwiper-2);
      

      width: $wh;
      height: $wh;

      @media (max-width: map-get($media, tl)){
        $wh: 36px;

        width: $wh;
        height: $wh;
      }

      @media (max-width: map-get($media, mx)){
        $wh: 30px;

        width: $wh;
        height: $wh;
      }

      border-radius: 1000px;
    };

    $spaceButtonSwiper: 50px;


    @media (max-width: map-get($media, tl)){
      padding: 0 $spaceButtonSwiper;
    }

    @include rp(tm){
      $spaceButtonSwiper: 36px;
      padding: 0 $spaceButtonSwiper;
    }
    // @include rp(mm){
    //   padding: 0;
    // }

    .swiper-button-next{
      right: calc(-#{$spaceButtonSwiper} - #{$wh});
      @media (max-width: map-get($media, pm)){
        right: calc(-20px - #{$wh});
      }
      @media (max-width: map-get($media, ps)){
        right: calc(-16px - #{$wh});
      }
      @media (max-width: map-get($media, tl)){
        right: 0;
      }
      @media (max-width: map-get($media, ts)){
        right: -8px;
      }
      @media (max-width: map-get($media, mx)){
        right: 0px;
      }
      @include rp(ml){
        right: 0px;
      }
    }
    .swiper-button-prev{
      left: calc(-#{$spaceButtonSwiper} - #{$wh});
      @media (max-width: map-get($media, pm)){
        left: calc(-20px - #{$wh});
      }
      @media (max-width: map-get($media, ps)){
        left: calc(-16px - #{$wh});
      }
      @media (max-width: map-get($media, tl)){
        left: 0;
      }
      @media (max-width: map-get($media, mx)){
        left: 0;
      }
      @include rp(ml){
        left: 0px;
      }
    }
    & .restroom-box {
      box-shadow: 0px 3px 6px rgba(#000, .16);
      background: map-get($color, mainBg);
      margin: 6px;
      &__img {
        max-width: 100%;
        @include blockIndex(imgIndex2, height);

        // height: 295px;
        
        // @media (max-width: map-get($media, pm)){
        //   height: 245px;
        // }
        // @media (max-width: map-get($media, tx)){
        //   height: 200px;
        // }
      }
      &__content{
        @include spaceIndex(s34, padding, 36px);
        @media (max-width: map-get($media, pl)){
          padding: 26px 22px;
        }
        @media (max-width: map-get($media, pm)){
          padding: 26px 24px;
        }
        @media (max-width: map-get($media, tx)){
          padding: 18px 20px;
        }
        @media (max-width: map-get($media, mm)){
          padding: 18px 18px;
        }
      }
      &__title {
        position: relative;
        &::after{
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 62px;
          height: 5px;
          background-color: map-get($color, underline );
          content: "";
        }
        &--size {
          // @include size(s41);
          @include title(s37);
          @include family--primary;
          @include p-line-clamp(1);

          line-height: 1.4;
          color: map-get($color, main);

        }
      }
      &__desc{
        @include spaceIndex(s34,margin,0);
        &>*{
          @include size(s18);
          @include p-line-clamp(4);
          text-align: justify;
          line-height: 1.4;
        }
        
      }
      &__bottom{
        display: flex;
        justify-content: space-between;
        @media (max-width: map-get($media, mx)){
          .btn-link--booking {
            padding: 8px 8px;
          }
        }
        // & .restroom-box__more{
          
        // }
      
      }
    }
  }
  &__more{
    @include spaceIndex(s72, padding-top);
    @include spaceIndex(s72, padding-bottom);

    text-align: center;
    &--size{
      @include size(s18);
      font-weight: light;
      color: map-get($color, mainBg);
      position: relative;
      
      &::after{
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        background: map-get($color , mainBg );
        width: 100%;
        height: 1px;
      }
      
    }
    
  }
}
